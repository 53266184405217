import { Button, IconButton } from '@mui/material';
import { React, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import { useNavigate } from 'react-router-dom';
import './PassedSnackbar.css';

function PassedSnackbar() {
    const [open, setOpen] = useState(true);
    const navigate = useNavigate();

    const vertical = 'top';
    const horizontal = 'right';

    // const handleClick = () => {
    //     setOpen(true);
    // };

    const handleClose = (event, reason) => {
        setOpen(false);
    };

    const goToExtension = () => {
        navigate('/extension');
    };

    const action = (
        <>
            <Button color='secondary' size='small' onClick={goToExtension}>
                Learn How
            </Button>
            <IconButton size='small' aria-label='close' color='inherit' onClick={handleClose}>
                <CloseIcon fontSize='small' />
            </IconButton>
        </>
    );

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2000}
                onClose={handleClose}
                message='Install the extension!'
                action={action}
                anchorOrigin={{ vertical, horizontal }}
            />
        </>
    );
}

export default PassedSnackbar;
