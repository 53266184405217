export const calcCredits = ({ wordCount, aiCheck, plagiarismCheck }) => {
    // console.log('wordCount', wordCount, aiCheck, plagiarismCheck);
    if (aiCheck && plagiarismCheck) {
        return Math.ceil(wordCount / 100) * 2;
    } else {
        return Math.ceil(wordCount / 100);
    }
};

export const wordCount = (e) => {
    return e.target.value.split(' ').filter(function (n) {
        return n !== '';
    }).length;
};

export const gDocWordCount = (content) => {
    return content.split(' ').filter(function (n) {
        return n !== '';
    }).length;
};

export function splitSentences(content) {
    if (content) {
        var result = content.split('\n');

        // foreach result remove any empty strings
        // and any strings with less than 10 words
        // TODO , flaw with this is it ditches ellipsis ... and replaces with .
        result = result
            .map((r) => {
                return r.split(/(?=[.;!?])|(?:[.;!?])/g);
            })
            .flat();

        let temp = result;
        temp.forEach((sentence, index) => {
            if (['.', ';', '!', '?'].includes(sentence)) {
                // add the single character sentence to the previous actual sentence.
                result[index - 1] = result[index - 1] + sentence;
            }
        });
        // remove any extra punctuation, empty strings
        //  any strings with less than 10 words
        result = result.filter(function (el) {
            return el.length !== 0 && el.length !== 1 && el.split(' ').length > 10;
        });

        return result;
    } else {
        return [];
    }
}
