import { GppGoodOutlined, RestartAltOutlined } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { useStoreState } from 'pullstate';
import { useState } from 'react';
import { CurrentScan } from '../../../stores/CurrentScan';
import { splitSentences } from '../../../utils/scanFns';
import { AppStore } from '../../../stores/AppStore';

const ScanButton = () => {
    const [loading, setLoading] = useState(false);
    const { userDoc } = useStoreState(AppStore);
    const { aiCheck, plagiarismCheck, content, wordCount, results, credits } =
        useStoreState(CurrentScan);
    const scanType = aiCheck && plagiarismCheck ? 0 : aiCheck && !plagiarismCheck ? 1 : 2;

    const scanNow = () => {
        setLoading(true);
        CurrentScan.update((s) => {
            s.scanDisabled = true;
            s.error = false;
        });
        const sentences = splitSentences(content);
        const title = content.substring(0, 50);
        //
        fetch(`https://us-central1-passedai.cloudfunctions.net/eScan`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                sentences: sentences,
                originalContent: content,
                creditCost: credits,
                scanType: scanType,
                title: title,
                user: userDoc.uid,
            }),
        })
            .then((response) => {
                // console.log('originality response', response);
                if (response.status === 200) {
                    AppStore.update((s) => {
                        s.userDoc.credits = s.userDoc.credits - credits;
                    });
                    return response.json();
                } else {
                    console.error('error getting originality score', response);
                    setLoading(false);
                }
            })
            .then((data) => {
                // console.log('originality data', data);
                CurrentScan.update((s) => {
                    s.results = data;
                    s.scanDisabled = false;
                });
                setLoading(false);
            })
            .catch((err) => {
                console.log('error', err);
                setLoading(false);
                CurrentScan.update((s) => {
                    s.error = true;
                    s.scanDisabled = false;
                });
            });
    };
    const resetScan = () => {
        CurrentScan.update((s) => {
            s.content = '';
            s.words = [];
            s.wordCount = 0;
            s.credits = 0;
            s.results = null;
            s.scanDisabled = false;
        });
    };
    return (
        <div style={{ padding: '0 1rem' }}>
            {!results ? (
                <>
                    <LoadingButton
                        disabled={wordCount < 50}
                        className='passed-btn full-width iconed'
                        loading={loading}
                        loadingPosition='start'
                        startIcon={<GppGoodOutlined />}
                        variant='contained'
                        color='secondary'
                        onClick={scanNow}
                        style={{ marginTop: '0.25rem', gap: '0 !important' }}
                    >
                        {!loading ? 'Scan Now' : 'Scanning...'}
                    </LoadingButton>
                </>
            ) : (
                <Button
                    className='passed-btn full-width iconed'
                    variant='contained'
                    color='secondary'
                    startIcon={<RestartAltOutlined />}
                    onClick={resetScan}
                >
                    Reset Scan
                </Button>
            )}
        </div>
    );
};

export default ScanButton;
