import React from 'react';
import { Card, CardContent, CardActions, Box } from '@mui/material';
import Header from '../MUI/Header';
import Subheader from '../MUI/Subheader';
import { useNavigate } from 'react-router-dom';
import PassedHyperlink from '../MUI/PassedHyperlink';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';

function AdminCard() {
    const { users, institutionDoc } = useStoreState(AppStore);
    const numberOfUsers = users?.length;
    const activeUsers = users?.length;
    const navigate = useNavigate();

    const goToManageUsers = () => {
        navigate('/manage-users');
    };

    const goToCredits = () => {
        navigate('/credits');
    };

    return (
        <Box sx={{ display: 'flex', gap: '2rem' }}>
            <Card className='mui-box' style={{ backgroundColor: '#F0F0F0', padding: '1rem' }}>
                <CardContent>
                    <Header text={`Number of Users`} />
                    <Header text={`${numberOfUsers}`} />
                    <Subheader text={`TOTAL USERS`} />
                </CardContent>
                <CardActions>
                    <PassedHyperlink onClick={goToManageUsers} text={'Manage'} />
                </CardActions>
            </Card>

            <Card className='mui-box' style={{ backgroundColor: '#F0F0F0', padding: '1rem' }}>
                <CardContent>
                    <Header text={`Active Users`} />
                    <Header text={`${activeUsers || '0'}`} />
                    <Subheader text={`ACCEPTED THE INVITE`} />
                </CardContent>
                <CardActions>
                    <PassedHyperlink onClick={goToManageUsers} text={'Manage'} />
                </CardActions>
            </Card>

            <Card className='mui-box' style={{ backgroundColor: '#F0F0F0', padding: '1rem' }}>
                <CardContent>
                    <Header text={`Credits Available`} />
                    <Header text={`${institutionDoc.credits || 0}`} />
                    <Subheader text={`FOR THIS MONTH`} />
                </CardContent>
                <CardActions>
                    <PassedHyperlink onClick={goToCredits} text={'Add Credits'} />
                </CardActions>
            </Card>
        </Box>
    );
}

export default AdminCard;
