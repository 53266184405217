import { Chip, FormControlLabel, FormGroup, Switch } from '@mui/material';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../stores/CurrentScan';
import './ScanOptions.css';

const ScanOptions = () => {
    const { wordCount, credits, aiCheck, plagiarismCheck, scanDisabled } =
        useStoreState(CurrentScan);

    const handleSwitch = (switchName) => {
        // console.log(switchName);
        CurrentScan.update((s) => {
            switch (switchName) {
                case 'aiCheck':
                    s.aiCheck = !s.aiCheck;
                    break;
                case 'plagiarismCheck':
                    s.plagiarismCheck = !s.plagiarismCheck;
                    break;
                default:
                    break;
            }
        });
    };
    return (
        <div className='ScanOptions'>
            <FormGroup className='scanOptionsSwitches'>
                <FormControlLabel
                    control={
                        <Switch
                            disabled={scanDisabled}
                            checked={aiCheck || false}
                            onChange={() => handleSwitch('aiCheck')}
                            color={aiCheck ? 'success' : 'primary'}
                        />
                    }
                    label='AI Content'
                />
                <FormControlLabel
                    control={
                        <Switch
                            disabled={scanDisabled}
                            checked={plagiarismCheck || false}
                            onChange={() => handleSwitch('plagiarismCheck')}
                            color={plagiarismCheck ? 'success' : 'primary'}
                        />
                    }
                    label='Plagiarized Content'
                />
            </FormGroup>

            <div className='scanOptionsStats'>
                <div>
                    <Chip
                        label={'Words: ' + wordCount}
                        color='primary'
                        variant='outlined'
                        className='scanChips'
                    />
                </div>
                <div>
                    <Chip
                        size='large'
                        label={'Credits: ' + credits}
                        color='primary'
                        variant='filled'
                        className='scanChips'
                    />
                </div>
            </div>
        </div>
    );
};

export default ScanOptions;
