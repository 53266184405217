import { addDocToSubCollection, editDocInSubCollection } from './getData';
import { read as readXLSX, utils as utilsXLSX } from 'xlsx';
import Papa from 'papaparse';
import { createNewUser } from '../../src/firebase';
import { AppStore } from '../stores/AppStore';

export const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
};

export const exportTemplate = (e) => {
    e.preventDefault();

    // Headers for each column
    let headers = ['Name', 'Email'];
    let csvContent = headers.join(',') + '\n';

    // Example data
    csvContent += 'User 1,example@example.com\n';
    csvContent += '...\n';

    downloadFile({
        data: csvContent,
        fileName: 'usersTemplate.csv',
        fileType: 'text/csv',
    });
};

export const manageDocInSubCollection = (
    mainCollection,
    user,
    docId,
    docData,
    userStore,
    setLoading,
    setAddManualOpen,

    setEditDocId
) => {
    setLoading(true);

    if (docId) {
        return editDocInSubCollection(mainCollection, user, docId, docData, userStore)
            .then(() => {
                setLoading(false);
                setAddManualOpen(false);

                setEditDocId(null); // reset after editing
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    } else {
        // Add new document
        return addDocToSubCollection(user, docData, userStore)
            .then(() => {
                setLoading(false);
                setAddManualOpen(false);
            })
            .catch((error) => {
                console.error(error);
                setLoading(false);
            });
    }
};

export const doesEmailExist = (arr, email) => arr.some((item) => item.email === email);

export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const handleDrop = (
    file,
    user,
    UserStore,
    setAlertOpen,
    setAlertMessage,
    institutionDoc,
    combinedData
) => {
    if (
        file.type !== 'text/csv' &&
        file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
        setAlertOpen(true);
        setAlertMessage('This file format is not supported. Please upload a CSV file.');
        return;
    } else if (file.size > 10485760) {
        setAlertOpen(true);
        setAlertMessage('File size is larger than 10MB.');
    } else if (file.type === 'text/csv') {
        Papa.parse(file, {
            preview: 1, // Parse only the first row for header verification
            header: false,
            complete: (results) => {
                const headers = results.data[0];

                if (headers[0].toLowerCase() !== 'name' || headers[1].toLowerCase() !== 'email') {
                    setAlertOpen(true);
                    setAlertMessage('Please make sure you are using our template.');
                    return;
                }

                // If headers are correct, parse the whole file
                Papa.parse(file, {
                    header: true, // In the final parse, set header to true to treat them as keys
                    complete: (results) => {
                        const data = results.data.map((row) => ({
                            displayName: row['Name'],
                            email: row['Email'],
                            credits: 200,
                        }));

                        const existingEmails = [];
                        const invalidEmails = [];

                        const duplicateEmails = [];

                        const emailSet = new Set();

                        data.forEach((item) => {
                            // Check for duplicates
                            if (emailSet.has(item.email)) {
                                duplicateEmails.push(item.email);
                            } else {
                                emailSet.add(item.email);
                            }

                            // Check if email already exists
                            if (doesEmailExist(combinedData, item.email)) {
                                existingEmails.push(item.email);
                            }

                            // Check for invalid email addresses
                            if (!emailRegex.test(item.email)) {
                                invalidEmails.push(item.email);
                            }
                        });

                        if (duplicateEmails.length > 0) {
                            alert(
                                `The email address(es) ${duplicateEmails.join(
                                    ', '
                                )} are duplicated in the file.`
                            );
                            return;
                        }
                        if (existingEmails.length > 0) {
                            alert(`The user(s) ${existingEmails.join(', ')} already exist.`);
                            return;
                        }

                        if (invalidEmails.length > 0) {
                            alert(`The email address(es) ${invalidEmails.join(', ')} are invalid.`);
                            return;
                        }

                        if (data.length * 200 > AppStore.getRawState().institutionDoc.credits) {
                            alert(
                                `Not enough available credits to invite ${
                                    data.length || 'all'
                                } users.`
                            );
                            return;
                        }

                        data.forEach((item) => {
                            AppStore.update((s) => {
                                s.institutionDoc.credits -= 200;
                                s.users.push(item);
                            });
                            createNewUser({
                                credits: 200,
                                email: item.email,
                                password: 'abcd1234',
                                displayName: item.displayName,
                                institutionId: institutionDoc.institutionId,
                                institutionName: institutionDoc.institution,
                                institutionLogo: '',
                            });
                        });
                    },
                });
            },
        });
    } else {
        const reader = new FileReader();

        reader.onload = (evt) => {
            const bstr = evt.target.result;
            const workbook = readXLSX(bstr, { type: 'binary' });

            const wsname = workbook.SheetNames[0];
            const ws = workbook.Sheets[wsname];

            const data = utilsXLSX.sheet_to_json(ws, { header: 1 });
            if (data[0][0].toLowerCase() !== 'name') {
                setAlertOpen(true);
                setAlertMessage('Please make sure you are using our template');
                return;
            }

            const dataItems = data.slice(1).map(([identifier]) => ({ identifier }));
            dataItems.forEach((row) => {
                addDocToSubCollection(user.uid, row, UserStore);
            });
        };

        reader.readAsBinaryString(file);
    }
};
