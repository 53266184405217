import { Grid } from '@mui/material';
import Scanner from '../../components/Scanner/Scanner';
import PassedSnackbar from '../../components/PassedSnackbar/PassedSnackbar';
import './ScanNow.css';
import Header from '../../components/MUI/Header';

const ScanNow = () => {
    // const location = window.location.pathname;
    // const urlParams = new URLSearchParams(window.location.search);
    // const fid = urlParams.get('fid');
    // const id = urlParams.get('id');

    return (
        <>
            <PassedSnackbar />
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Header text='Content Scanner' />
                </Grid>
                <Grid item xs={12} md={6}></Grid>
            </Grid>

            <Scanner />
        </>
    );
};

export default ScanNow;
