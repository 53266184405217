import React from 'react';
import EmailIcon from '@mui/icons-material/Email';
import { Fab, Link, Box } from '@mui/material';
import EmailRobot from '../../assets/images/EmailRobot.gif';
import './MuiStyles.css';
// import RobotBox from '../../components/Onboarding/RobotBox';

function EmailFab() {
    return (
        <Box className='emailFab'>
            <Box
                style={{
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    paddingBottom: '2rem',
                }}
            >
                <img className='robot-app' src={EmailRobot} alt='Robot'></img>
            </Box>
            <Fab
                variant='extended'
                style={{
                    top: 'auto',
                    right: 20,
                    bottom: 20,
                    left: 'auto',
                    position: 'fixed',
                    backgroundColor: '#FCBA04',
                    color: 'black',
                    margin: '0.5rem',
                    padding: '1rem',
                    borderRadius: '10px',
                    lineHeight: '1.25rem',
                }}
            >
                <EmailIcon sx={{ mr: 1 }} />
                <Link underline='none' href='mailto:hello@passedai.com'>
                    QUESTIONS? Email Us
                </Link>
            </Fab>
        </Box>
    );
}

export default EmailFab;
