import { React, useState, useEffect, useRef } from 'react';
import MUIDataTable from 'mui-datatables';
import { Box, TextField, Button, Alert, Tooltip, Zoom } from '@mui/material';
import {
    Check,
    Clear,
    Delete,
    Edit,
    FiberManualRecord,
    AddCircle,
    Star,
} from '@mui/icons-material';
import { manageDocInSubCollection, doesEmailExist, emailRegex } from '../../utils/uploadFns';
import { deleteDocFromSubCollection, changeCredits } from '../../utils/getData';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { UserStore } from '../../stores/UserStore';
import PassedModal from './PassedModal';
import { createNewUser } from '../../../src/firebase';

import './PassedMUIDataTable.css';
import { PassedLoader } from '../MUI/PassedLoader';

const MyDataTable = ({ addManualOpen, setAddManualOpen, data: initialdata }) => {
    const { userDoc, institutionDoc, users } = useStoreState(AppStore);
    const [data, setData] = useState(initialdata);
    const [newData, setNewData] = useState({
        displayName: '',
        email: '',

        credits: '200',
    });
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [editDocId, setEditDocId] = useState(null);
    const [updateData, setUpdateData] = useState({});
    const [editingRowId, setEditingRowId] = useState(-1);
    const [modalData, setModalData] = useState({
        email: '',
        credits: '',
        username: '',
    });
    const [openModal, setOpenModal] = useState(false);

    const startUserOnboarding = () => {
        AppStore.update((s) => {
            const newUser = {
                displayName: newData.displayName,
                email: newData.email,
                credits: 200,
            };
            s.users.push(newUser);
        });
        createNewUser({
            email: newData.email,
            password: 'abcd1234',
            displayName: newData.displayName,
            credits: 200,
            institutionId: institutionDoc.institutionId,
            institutionName: institutionDoc.institution,
            institutionLogo: '',
        }).then((result) => {
            console.log('result', result);
        });
    };
    const tableRef = useRef(null);

    const addDoc = () => {
        const filled = (data) => {
            for (let field in data) {
                if (!data[field]) {
                    // If any field in the data is not filled
                    return false;
                }
            }
            return true;
        };

        let validData = true;
        if (editDocId) {
            if (!filled(updateData)) {
                validData = false;
                setAlertOpen(true);
                setAlertMessage('Please make sure to fill in every updated field.');
            }
        } else {
            if (!filled(newData)) {
                validData = false;
                setAlertOpen(true);
                setAlertMessage('Please make sure to fill in every field for adding.');
            }
        }

        if (validData) {
            if (
                newData.displayName ||
                newData.email ||
                updateData.displayName ||
                updateData.email
            ) {
                manageDocInSubCollection(
                    'users',
                    userDoc,

                    editDocId,
                    editDocId ? updateData : newData,
                    UserStore,
                    setLoading,
                    setAddManualOpen,
                    setEditDocId
                );

                setData((prevData) => [newData, ...prevData]);
                setNewData({ displayName: '', email: '', credits: '200' });
                setAddManualOpen(false);
            }
        }
    };

    const options = {
        download: false,
        selectableRows: 'none',
    };

    const columns = [
        {
            name: 'Name',
            label: 'Name',
            options: {
                setCellProps: () => ({
                    style: {
                        width: 'auto', // Adjust width to auto
                        whiteSpace: 'normal', // Ensure text wraps
                    },
                }),
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    if (data[dataIndex]?.id === editingRowId) {
                        return (
                            <TextField
                                inputProps={{
                                    style: {
                                        padding: '0.5rem',
                                    },
                                }}
                                defaultValue={
                                    data[dataIndex].displayName || data[dataIndex.firstName]
                                }
                                onChange={(e) => {
                                    setUpdateData((prevData) => ({
                                        ...prevData,
                                        displayName: e.target.value,
                                    }));
                                }}
                            />
                        );
                    } else if (data[dataIndex]?.isNew) {
                        return (
                            <TextField
                                inputProps={{
                                    style: {
                                        padding: '0.5rem',
                                    },
                                }}
                                defaultValue={data[dataIndex].displayName}
                                onChange={(e) => {
                                    setNewData((prevData) => ({
                                        ...prevData,
                                        displayName: e.target.value,
                                    }));
                                }}
                            />
                        );
                    }
                    return data[dataIndex]?.displayName || '';
                },
            },
        },
        {
            name: 'Email',
            label: 'Email',
            options: {
                setCellProps: () => ({
                    style: {
                        width: 'auto', // Adjust width to auto
                        whiteSpace: 'normal', // Ensure text wraps
                    },
                }),
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    if (data[dataIndex]?.id === editingRowId) {
                        return (
                            <TextField
                                inputProps={{
                                    style: {
                                        padding: '0.5rem',
                                    },
                                }}
                                defaultValue={data[dataIndex].email}
                                onChange={(e) => {
                                    setUpdateData((prevData) => ({
                                        ...prevData,
                                        email: e.target.value,
                                    }));
                                }}
                            />
                        );
                    } else if (data[dataIndex]?.isNew) {
                        return (
                            <TextField
                                inputProps={{
                                    style: {
                                        padding: '0.5rem',
                                    },
                                }}
                                defaultValue={data[dataIndex].email}
                                onChange={(e) => {
                                    setNewData((prevData) => ({
                                        ...prevData,
                                        email: e.target.value,
                                    }));
                                }}
                            />
                        );
                    }
                    return data[dataIndex]?.email || '';
                },
            },
        },
        // {
        //     name: 'Dept',
        //     label: 'Dept',
        //     options: {
        //         setCellProps: () => ({
        //             style: {
        //                 width: 'auto', // Adjust width to auto
        //                 whiteSpace: 'normal', // Ensure text wraps
        //             },
        //         }),
        //         filter: true,
        //         sort: true,
        //         customBodyRenderLite: (dataIndex) => {
        //             if (data[dataIndex]?.id === editingRowId) {
        //                 return (
        //                     <TextField
        //                         inputProps={{
        //                             style: {
        //                                 padding: '0.5rem',
        //                             },
        //                         }}
        //                         defaultValue={data[dataIndex].department}
        //                         onChange={(e) => {
        //                             setUpdateData((prevData) => ({
        //                                 ...prevData,
        //                                 department: e.target.value,
        //                             }));
        //                         }}
        //                     />
        //                 );
        //             } else if (data[dataIndex]?.isNew) {
        //                 return (
        //                     <TextField
        //                         required
        //                         inputProps={{
        //                             style: {
        //                                 padding: '0.5rem',
        //                             },
        //                         }}
        //                         defaultValue={data[dataIndex].department}
        //                         onChange={(e) => {
        //                             setNewData((prevData) => ({
        //                                 ...prevData,
        //                                 department: e.target.value,
        //                             }));
        //                         }}
        //                     />
        //                 );
        //             }
        //             return data[dataIndex]?.department || '';
        //         },
        //     },
        // },
        // {
        //     name: 'Used Credits',
        //     label: 'Used Credits',
        //     options: {
        //         setCellProps: () => ({
        //             style: {
        //                 width: 'auto', // Adjust width to auto
        //                 whiteSpace: 'normal', // Ensure text wraps
        //             },
        //         }),
        //         filter: true,
        //         sort: true,
        //         customBodyRenderLite: (dataIndex) => {
        //             return data[dataIndex]?.usedCredits || '';
        //         },
        //     },
        // },
        {
            name: 'Current Credits',
            label: 'Current Credits',
            options: {
                setCellProps: () => ({
                    style: {
                        width: 'auto', // Adjust width to auto
                        whiteSpace: 'normal', // Ensure text wraps
                    },
                }),
                filter: true,
                sort: true,
                customBodyRenderLite: (dataIndex) => {
                    const currentItem = data[dataIndex];
                    if (data[dataIndex]?.isNew) {
                        return <p>200</p>;
                    }
                    return (
                        <Box className='current_credits_box'>
                            <Box className='current_credits'>{data[dataIndex]?.credits || 0}</Box>
                            <Tooltip
                                TransitionComponent={Zoom}
                                TransitionProps={{ timeout: 600 }}
                                title={
                                    currentItem?.type === 'invite'
                                        ? 'New users are automatically assigned 200 credits. Please wait for the user to accept their invite to change this.'
                                        : ''
                                }
                            >
                                <span>
                                    <Button
                                        className={`current_credits_button ${
                                            currentItem?.type === 'invite' ? 'disabled_button' : ''
                                        }`}
                                        onClick={() => {
                                            if (currentItem?.type !== 'invite') {
                                                setOpenModal(true);
                                                setModalData({
                                                    email: data[dataIndex]?.email,
                                                    credits: Number(data[dataIndex]?.credits),
                                                });
                                            }
                                        }}
                                        disabled={currentItem?.type === 'invite'}
                                    >
                                        <AddCircle className='current_credits_icon' />
                                    </Button>
                                </span>
                            </Tooltip>
                        </Box>
                    );
                },
            },
        },
        {
            name: 'Status',
            label: 'Status',
            options: {
                setCellProps: () => ({
                    style: {
                        width: 'auto', // Adjust width to auto
                        whiteSpace: 'normal', // Ensure text wraps
                    },
                }),
                filter: true,
                sort: true,
                download: false,
                customBodyRenderLite: (dataIndex) => {
                    const currentItem = data[dataIndex];
                    if (currentItem?.admin) {
                        return (
                            <Box className='status_box'>
                                <Star className='status_icon active' />
                                <p>Admin</p>
                            </Box>
                        );
                    } else if (currentItem?.ready) {
                        return (
                            <Box className='status_box'>
                                <FiberManualRecord className='status_icon active' />
                                <p>Active</p>
                            </Box>
                        );
                    } else if (!currentItem?.ready) {
                        return (
                            <Box className='status_box'>
                                <FiberManualRecord className='status_icon' />
                                <p>Invited</p>
                            </Box>
                        );
                    }
                    if (data[dataIndex]?.isNew) {
                        return (
                            <Box className='status_box'>
                                <FiberManualRecord className='status_icon' />
                                <p>Invited</p>
                            </Box>
                        );
                    }
                    return (
                        <Box className={'status_box'}>
                            <FiberManualRecord className='status_icon' />
                            <p>Invited</p>
                        </Box>
                    );
                },
            },
        },
        {
            name: '',
            label: '',

            options: {
                setCellProps: () => ({
                    style: {
                        width: 'auto', // Adjust width to auto
                        whiteSpace: 'normal', // Ensure text wraps
                        textAlign: 'center',
                    },
                }),
                customBodyRenderLite: (dataIndex) => {
                    if (data[dataIndex]?.isNew) {
                        return (
                            <>
                                <Button
                                    className='databutton confirm'
                                    variant='contained'
                                    onClick={async () => {
                                        if (!emailRegex.test(newData.email)) {
                                            alert('The entered email address is invalid');
                                            return;
                                        }

                                        if (!newData.displayName) {
                                            alert('Please enter a name');
                                            return;
                                        }

                                        // Check if email already exists
                                        if (doesEmailExist(users, newData.email)) {
                                            alert('A user with this email already exists.');
                                            return;
                                        }

                                        // Check if enough credits are available
                                        if (institutionDoc.credits - 200 < 0) {
                                            alert(
                                                'Not enough credits available to add a new user.'
                                            );
                                            return;
                                        }

                                        // Update frontend state
                                        AppStore.update((s) => {
                                            if (s.institutionDoc) {
                                                s.institutionDoc.credits -= 200;
                                            }
                                        });

                                        const remainingData = data.filter((row) => !row.isNew);
                                        setData(remainingData);
                                        startUserOnboarding();
                                    }}
                                >
                                    <Check />
                                </Button>
                                <Button
                                    variant='contained'
                                    className='databutton delete'
                                    onClick={() => {
                                        setNewData({
                                            displayName: '',
                                            email: '',
                                            credits: '200',
                                        });
                                        const remainingData = data.filter((row) => !row.isNew);
                                        setData(remainingData);
                                        setAddManualOpen(false);
                                    }}
                                >
                                    <Clear />
                                </Button>
                            </>
                        );
                    } else if (data[dataIndex]?.id === editingRowId) {
                        return (
                            <>
                                <Button
                                    variant='contained'
                                    className='databutton confirm'
                                    onClick={() => {
                                        const originalRow =
                                            data.find((row) => row.id === editingRowId) || {};

                                        const isEmailUpdated = 'email' in updateData;

                                        const isDisplayNameUpdated = 'displayName' in updateData;

                                        const emailToCheck = isEmailUpdated
                                            ? updateData.email
                                            : originalRow.email;
                                        const displayNameToCheck = isDisplayNameUpdated
                                            ? updateData.displayName
                                            : originalRow.displayName;

                                        if (isEmailUpdated && !emailRegex.test(emailToCheck)) {
                                            alert('The entered email address is invalid');
                                            return;
                                        }
                                        if (isDisplayNameUpdated && !displayNameToCheck) {
                                            alert('Please enter a name');
                                            return;
                                        }
                                        addDoc();
                                        const updatedRows = [...data];
                                        const indexToUpdate = updatedRows.findIndex(
                                            (row) => row.id === editingRowId
                                        );

                                        if (indexToUpdate !== -1) {
                                            updatedRows[indexToUpdate] = {
                                                ...updatedRows[indexToUpdate],
                                                ...updateData,
                                            };
                                        }

                                        setData(updatedRows);
                                        setEditingRowId(-1);
                                        setUpdateData({});
                                    }}
                                >
                                    <Check />
                                </Button>
                                <Button
                                    variant='contained'
                                    className='databutton delete'
                                    onClick={() => {
                                        setEditDocId(null);
                                        setEditingRowId(null);
                                    }}
                                >
                                    <Clear />
                                </Button>
                            </>
                        );
                    }
                    //firebase rows
                    return (
                        <>
                            <Button
                                variant='contained'
                                className={`databutton fb ${
                                    data[dataIndex]?.admin ? 'disabled_button' : ''
                                }`}
                                disabled={data[dataIndex]?.admin}
                                onClick={() => {
                                    const docId = data[dataIndex].id;

                                    if (docId) {
                                        setEditDocId(docId);
                                        setEditingRowId(editingRowId === docId ? -1 : docId);
                                    }
                                }}
                            >
                                <Edit />
                            </Button>
                            <Button
                                variant='contained'
                                className={`databutton fb delete ${
                                    data[dataIndex]?.admin ? 'disabled_button' : ''
                                }`}
                                disabled={data[dataIndex]?.admin}
                                onClick={async () => {
                                    const userToDelete = data[dataIndex];

                                    await changeCredits(
                                        institutionDoc.institutionId,
                                        Number(userToDelete.credits)
                                    );

                                    deleteDocFromSubCollection(
                                        userToDelete.type,
                                        userToDelete.id,
                                        userToDelete.email,
                                        UserStore
                                    );
                                    const newData = [...data];
                                    newData.splice(dataIndex, 1);
                                    setData(newData);
                                }}
                            >
                                <Delete />
                            </Button>
                        </>
                    );
                },
            },
        },
    ];

    useEffect(() => {
        if (addManualOpen) {
            const hasInputRow = data.some((row) => row.isNew);

            if (!hasInputRow) {
                setData((prevData) => [
                    { isNew: true, displayName: '', email: '', credits: '200' },
                    ...prevData,
                ]);
            }

            if (tableRef.current) {
                tableRef.current.changePage(0);
            }
            setAddManualOpen(false);
        }
    }, [addManualOpen, setAddManualOpen, data]);

    useEffect(() => {
        setData(initialdata);
    }, [initialdata]);

    if (loading) {
        return <PassedLoader />;
    } else {
        return (
            <>
                <PassedModal open={openModal} user={modalData} setOpenModal={setOpenModal} />

                <Box>
                    {alertOpen && (
                        <Alert
                            severity='warning'
                            sx={{
                                m: '-0.5rem 0 1rem 0',
                            }}
                        >
                            {alertMessage}
                        </Alert>
                    )}

                    <MUIDataTable
                        ref={tableRef}
                        data={addManualOpen ? [newData, ...data] : data}
                        columns={columns}
                        options={options}
                        title={'Employee List'}
                    />
                </Box>
            </>
        );
    }
};

export default MyDataTable;
