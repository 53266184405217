import React, { useRef, useEffect, useState } from 'react';
import './DoughnutChart.css';
const DoughnutChart = ({ data, size, chartlabel }) => {
    const containerRef = useRef();
    const [containerSize, setContainerSize] = useState(size);

    useEffect(() => {
        const handleResize = () => {
            setContainerSize(containerRef.current.clientWidth);
        };
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const sum = data.reduce((acc, { value }) => acc + value, 0);
    const radius = (containerSize * 0.75) / 2; // Subtract 2 to prevent cutting off the edges
    const strokeWidth = radius / 10;

    const getPathData = (startAngle, endAngle) => {
        const x1 = containerSize / 2 + radius * Math.cos(startAngle);
        const y1 = containerSize / 2 + radius * Math.sin(startAngle);
        const x2 = containerSize / 2 + radius * Math.cos(endAngle);
        const y2 = containerSize / 2 + radius * Math.sin(endAngle);
        const largeArc = endAngle - startAngle <= Math.PI ? 0 : 1;
        return `M${x1} ${y1} A ${radius} ${radius} 0 ${largeArc} 1 ${x2} ${y2}`;
    };

    let currentAngle = -Math.PI / 2; // Start at the top of the circle
    return (
        <div ref={containerRef}>
            <svg
                width={containerSize}
                height={containerSize}
                viewBox={`0 0 ${containerSize} ${containerSize}`}
            >
                {data.map(({ label, value, color }) => {
                    const startAngle = currentAngle;
                    const endAngle = currentAngle + (value / sum) * 2 * Math.PI;
                    const pathData = getPathData(startAngle, endAngle);
                    currentAngle = endAngle;

                    return (
                        <path
                            key={label}
                            d={pathData}
                            fill='none'
                            stroke={color}
                            strokeWidth={strokeWidth}
                            strokeLinecap='butt'
                        />
                    );
                })}
                <foreignObject
                    x={containerSize / 2 - radius / 2}
                    y={containerSize / 2 - radius / 2}
                    width={radius}
                    height={radius}
                    className='doughnut-chart__inner'
                >
                    {chartlabel}
                </foreignObject>
            </svg>
        </div>
    );
};

export default DoughnutChart;
