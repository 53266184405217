import { React, useEffect, useState, forwardRef } from 'react';

import Header from '../../components/MUI/Header';
import { Box, Modal } from '@mui/material';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { AppStore } from '../../stores/AppStore';
import { useStoreState } from 'pullstate';
import PaymentInfo from '../../components/PaymentInfo/PaymentInfo';
import Invoices from '../../components/Invoices/Invoices';
import PassedButton from '../../components/MUI/PassedButton';
import { getCustomerBillingDetails } from '../../firebase';

import './ManageAccount.css';
import Users from '../../components/ManageAccount/Users';
import { useNavigate } from 'react-router-dom';

const ManageAccount = () => {
    //const [userCount, setUserCount] = useState(0);
    const navigate = useNavigate();
    const [modal, setModal] = useState(false);
    const { userDoc } = useStoreState(AppStore);
    const stripePromise = loadStripe(
        'pk_test_51MbViQGJ1Yvc8jVWAVW60cy59jrYWrQ6peiITOFEkM4PyNSOUDtbD4Ah1dK2BApWT264CpmCkgVZSbeTNaxV4W1F00ConyhpAL'
    );
    const RefWrapper = forwardRef((props, ref) => {
        // This component doesn't use the forwarded ref.
        return <>{props.children}</>;
    });
    useEffect(() => {
        if (userDoc.stripeId) {
            getCustomerBillingDetails(userDoc.stripeId);
        }
    });

    const openModal = () => {
        setModal(true);
    };

    const closeModal = () => {
        setModal(false);
    };

    // const getUserCount = () => {
    //     if (userDoc.users) {
    //         setUserCount(userDoc.users.length);
    //     }
    // };

    // useEffect(() => {
    //     getUserCount();
    // }, [getUserCount]);

    return (
        <>
            <Header text='Manage Account' />
            {userDoc.active && (
                <Box className='manageaccount_container'>
                    <Box className='manageaccount_box'>
                        <Box className='manageaccount_box_content'>
                            <Box className='header'>USERS</Box>
                            <Box className='manageaccount_box_text'>
                                <Users />
                            </Box>
                            <PassedButton
                                text='View more'
                                onClick={() => navigate('/manage-users')}
                            />
                        </Box>
                    </Box>
                    <Box className='manageaccount_box'>
                        <Box className='manageaccount_box_content'>
                            <Box className='header'>INVOICES</Box>
                            <Box>
                                <Invoices />
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Box
                            className='manageaccount_box small billing'
                            style={{ height: '18rem' }}
                        >
                            {/* <Divider orientation='vertical' /> */}

                            {/* <Box className='billing_container'>
                                <CardHeader text='Billing Info' />
                                <BillingInfo />
                            </Box> */}
                        </Box>
                    </Box>
                </Box>
            )}

            {!userDoc.active && (
                <PassedButton text='Set up your Subscription' onClick={openModal} />
            )}
            <Modal
                open={modal}
                onClose={closeModal}
                aria-labelledby='payment-info'
                aria-describedby='update payment info'
            >
                <RefWrapper>
                    <Elements stripe={stripePromise}>
                        <PaymentInfo />
                    </Elements>
                </RefWrapper>
            </Modal>
        </>
    );
};

export default ManageAccount;
