import { Logout, Settings } from '@mui/icons-material';
import {
    Divider,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from '@mui/material';
import { useStoreState } from 'pullstate';
import { logout } from '../../../firebase';
import { AppStore } from '../../../stores/AppStore';
import { NavLink } from 'react-router-dom';
import PassedIcon from '../../PassedIcon/PassedIcon';

const DrawerFooter = ({ width }) => {
    const { user } = useStoreState(AppStore);

    return (
        <List className='drawer-footer' style={{ width: width }}>
            <Divider />
            <NavLink className='side-nav-link' to='/extension'>
                <ListItem>
                    <ListItemIcon>
                        <PassedIcon iconPath='M20.5 11H19V7c0-1.1-.9-2-2-2h-4V3.5C13 2.12 11.88 1 10.5 1S8 2.12 8 3.5V5H4c-1.1 0-1.99.9-1.99 2v3.8H3.5c1.49 0 2.7 1.21 2.7 2.7s-1.21 2.7-2.7 2.7H2V20c0 1.1.9 2 2 2h3.8v-1.5c0-1.49 1.21-2.7 2.7-2.7 1.49 0 2.7 1.21 2.7 2.7V22H17c1.1 0 2-.9 2-2v-4h1.5c1.38 0 2.5-1.12 2.5-2.5S21.88 11 20.5 11z' />
                    </ListItemIcon>
                    <ListItemText primary='Get the Extension' />
                </ListItem>
            </NavLink>

            <NavLink className='side-nav-link' to='/account'>
                <ListItem
                    secondaryAction={
                        <IconButton edge='end' aria-label='delete'>
                            <Settings />
                        </IconButton>
                    }
                >
                    <ListItemIcon>
                        <img
                            src={
                                user?.photoURL ||
                                'https://lh3.googleusercontent.com/a/default-user=s96-c'
                            }
                            alt='user'
                            referrerPolicy='no-referrer'
                            className='footer__user__image'
                        />
                    </ListItemIcon>
                    <ListItemText className='user-email' primary={user.email} />
                </ListItem>
            </NavLink>
            <ListItemButton onClick={() => logout()}>
                <ListItemIcon>
                    <Logout />
                </ListItemIcon>
                <ListItemText primary='Logout' />
            </ListItemButton>
        </List>
    );
};

export default DrawerFooter;
