import { Payment } from '@mui/icons-material';
import {
    AppBar,
    Chip,
    Divider,
    Drawer,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Toolbar,
    Typography,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import useMediaQuery from '@mui/material/useMediaQuery';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import { useStoreState } from 'pullstate';
// import Logo from '../../assets/images/Blue-Inline.png';
import Logo from '../../assets/images/blue-inline.svg';
import { AppStore } from '../../stores/AppStore';
import { Link, NavLink } from 'react-router-dom';
import './Navigation.css';
import DrawerFooter from './DrawerFooter/DrawerFooter';
import { useState, useEffect } from 'react';
import PassedIcon from '../PassedIcon/PassedIcon';

const navItems = [
    {
        name: 'Dashboard',
        icon: (
            <PassedIcon iconPath='M3 13h8V3H3v10zm0 8h8v-6H3v6zm10 0h8V11h-8v10zm0-18v6h8V3h-8z' />
        ),
        link: '/dashboard',
    },
    {
        name: 'Manage Users',
        icon: (
            <PassedIcon iconPath='M20.5 6c-2.61.7-5.67 1-8.5 1s-5.89-.3-8.5-1L3 8c1.86.5 4 .83 6 1v13h2v-6h2v6h2V9c2-.17 4.14-.5 6-1l-.5-2zM12 6c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z' />
        ),
        link: '/manage-users',
    },
    {
        name: 'Manage Account',
        icon: (
            <PassedIcon iconPath='M19 3H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 3c1.93 0 3.5 1.57 3.5 3.5S13.93 13 12 13s-3.5-1.57-3.5-3.5S10.07 6 12 6zm7 13H5v-.23c0-.62.28-1.2.76-1.58C7.47 15.82 9.64 15 12 15s4.53.82 6.24 2.19c.48.38.76.97.76 1.58V19z' />
        ),
        link: '/manage-account',
    },
    {
        name: 'Add Credits',
        icon: (
            <PassedIcon iconPath='m21.9 8.89-1.05-4.37c-.22-.9-1-1.52-1.91-1.52H5.05c-.9 0-1.69.63-1.9 1.52L2.1 8.89c-.24 1.02-.02 2.06.62 2.88.08.11.19.19.28.29V19c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6.94c.09-.09.2-.18.28-.28.64-.82.87-1.87.62-2.89zm-2.99-3.9 1.05 4.37c.1.42.01.84-.25 1.17-.14.18-.44.47-.94.47-.61 0-1.14-.49-1.21-1.14L16.98 5l1.93-.01zM13 5h1.96l.54 4.52c.05.39-.07.78-.33 1.07-.22.26-.54.41-.95.41-.67 0-1.22-.59-1.22-1.31V5zM8.49 9.52 9.04 5H11v4.69c0 .72-.55 1.31-1.29 1.31-.34 0-.65-.15-.89-.41-.25-.29-.37-.68-.33-1.07zm-4.45-.16L5.05 5h1.97l-.58 4.86c-.08.65-.6 1.14-1.21 1.14-.49 0-.8-.29-.93-.47-.27-.32-.36-.75-.26-1.17zM5 19v-6.03c.08.01.15.03.23.03.87 0 1.66-.36 2.24-.95.6.6 1.4.95 2.31.95.87 0 1.65-.36 2.23-.93.59.57 1.39.93 2.29.93.84 0 1.64-.35 2.24-.95.58.59 1.37.95 2.24.95.08 0 .15-.02.23-.03V19H5z' />
        ),
        link: '/credits',
    },
    {
        name: 'Plans',
        icon: <Payment />,
        link: '/subscribe',
    },
    {
        name: 'Help',
        icon: (
            <PassedIcon iconPath='M11.07 12.85c.77-1.39 2.25-2.21 3.11-3.44.91-1.29.4-3.7-2.18-3.7-1.69 0-2.52 1.28-2.87 2.34L6.54 6.96C7.25 4.83 9.18 3 11.99 3c2.35 0 3.96 1.07 4.78 2.41.7 1.15 1.11 3.3.03 4.9-1.2 1.77-2.35 2.31-2.97 3.45-.25.46-.35.76-.35 2.24h-2.89c-.01-.78-.13-2.05.48-3.15zM14 20c0 1.1-.9 2-2 2s-2-.9-2-2 .9-2 2-2 2 .9 2 2z' />
        ),
        link: '/help',
    },
];
const Navigation = () => {
    const [displaySidebar, setDisplaySidebar] = useState(true);
    const { drawerWidth, userDoc, institutionDoc } = useStoreState(AppStore);

    const mobile = useMediaQuery('(max-width:650px)');

    useEffect(() => {
        if (mobile) {
            setDisplaySidebar(false);
        }
    }, [mobile]);

    const filteredNavItems = navItems.filter((item) => {
        if (item.name === 'Plans' && userDoc.active) {
            return false;
        } else if (item.name === 'Add Credits' && !userDoc.active) {
            return false;
        }

        return true;
    });

    const toggleSidebar = () => {
        setDisplaySidebar(!displaySidebar);
    };

    return (
        <>
            <AppBar
                position='fixed'
                display='flex'
                flex='1'
                sx={{ width: '100%', ml: `${drawerWidth}px` }}
            >
                <Toolbar className='nav-toolbar'>
                    <MenuIcon onClick={toggleSidebar} style={{ cursor: 'pointer' }} />
                    <Typography variant='h6' noWrap component='div'>
                        <Link onClick={toggleSidebar} className='creditsChip' to={'/credits'}>
                            <Chip
                                label={
                                    institutionDoc.credits
                                        ? 'Credits: ' + institutionDoc.credits
                                        : 'Credits: ' + 0
                                }
                                sx={{
                                    cursor: 'pointer',
                                    fontWeight: 'bold',
                                    color: 'white', // set the text color that contrasts your gradient colors
                                    backgroundImage:
                                        'linear-gradient(45deg, #30bced 50%, #6ECFF1 100%)',
                                }}
                            />
                        </Link>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                // style={{ display: 'none' }}
                // style={{ display: 'block' }}
                style={
                    mobile
                        ? displaySidebar
                            ? { display: 'block' }
                            : { display: 'none' }
                        : { display: 'block' }
                }
                sx={{
                    width: 280,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: 280,
                        boxSizing: 'border-box',
                    },
                }}
                variant='permanent'
                anchor='left'
            >
                <Toolbar
                    style={{
                        display: mobile ? 'flex' : 'none',
                        flexDirection: 'row-reverse',
                        backgroundColor: '#1f2641',
                        color: 'white',
                    }}
                >
                    <KeyboardDoubleArrowLeftIcon
                        style={mobile ? { display: 'block' } : { display: 'none' }}
                        onClick={toggleSidebar}
                    />
                </Toolbar>
                <Toolbar>
                    <img src={Logo} alt='Passed.ai' className='header-logo' />
                    <Chip label='Beta' size='small' color='secondary' />
                </Toolbar>
                <Divider />
                <List>
                    {filteredNavItems.map((item, i) => {
                        if (!userDoc.active && item.name === 'Scan Now') {
                            return false;
                        } else {
                            return (
                                <NavLink className='side-nav-link' to={item.link} key={i}>
                                    <ListItemButton>
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.name} />
                                    </ListItemButton>
                                </NavLink>
                            );
                        }
                    })}
                </List>
                <DrawerFooter width={drawerWidth} />
            </Drawer>
        </>
    );
};

export default Navigation;
