import { useState, useEffect } from 'react';
import './HelpCard.css';
import { Typography } from '@mui/material';

const HelpTOC = (props) => {
    const [cards, setCards] = useState(props.cards);

    useEffect(() => {
        setCards(props.cards);
    }, [props]);

    return (
        <div id={props.id}>
            <div className='card-container'>
                <div className='card-top'>
                    <h2>
                        <Typography
                            sx={{ mt: 0, mb: 0 }}
                            variant='h4'
                            component='div'
                            // className='affiliate-card-title'
                        >
                            Table of Contents
                        </Typography>
                    </h2>
                </div>
                <div className='card-bottom'>
                    <ul>
                        {Array.isArray(cards) &&
                            cards.map((element, i) => {
                                return (
                                    <li key={i}>
                                        <a href={'#help-' + i}>{element.header}</a>
                                    </li>
                                );
                            })}
                        <li key='videos'>
                            <a href='#videos'>Videos</a>
                        </li>
                        {/* <li key='FAQs'>
                            <a href='#FAQs'>FAQs</a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HelpTOC;
