import { React, useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import PassedMUIDataTable from '../../components/PassedMUIDataTable/PassedMUIDataTable.jsx';
import { useStoreState } from 'pullstate';
import { UserStore } from '../../stores/UserStore';
import { AppStore } from '../../stores/AppStore.js';
import PassedButton from '../MUI/PassedButton';
import { Box, Alert, Button } from '@mui/material';
import { AddCircleOutline, CloudUpload, DownloadForOffline } from '@mui/icons-material';
import { exportTemplate, handleDrop } from '../../utils/uploadFns';
import { updateInstitutionSubUserCredits } from '../../utils/getData';

import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import './ManageUsers.css';

function ManageUsersTable() {
    const [addManualOpen, setAddManualOpen] = useState(false);
    const [addCSVOpen, setAddCSVOpen] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [userData, setUserData] = useState([]);

    const { users, institutionDoc } = useStoreState(AppStore);

    updateInstitutionSubUserCredits(institutionDoc.institutionId);

    useEffect(() => {
        setUserData(users);
    }, [users]);

    const addUser = () => {
        // console.log('adding user');
        setAddManualOpen(true);
        setAddCSVOpen(false);
    };

    const uploadCSV = () => {
        setAddCSVOpen(!addCSVOpen);
        setAddManualOpen(false);
    };

    const onDrop = useCallback(
        (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                handleDrop(
                    file,
                    institutionDoc,
                    UserStore,
                    setAlertOpen,
                    setAlertMessage,
                    institutionDoc,
                    userData
                );
            });
        },
        [institutionDoc, userData]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
                '.doc',
                '.docx',
            ],
        },
        className: 'drop-zone active',
    });

    return (
        <>
            <Box className='users_container'>
                <PassedButton
                    onClick={addUser}
                    text='Add User'
                    disabled={institutionDoc.subQuantity <= userData.length}
                    icon={<AddCircleOutline />}
                />
                <PassedButton
                    onClick={uploadCSV}
                    text='Upload CSV'
                    disabled={institutionDoc.subQuantity <= userData.length}
                    icon={<DriveFolderUploadIcon />}
                />
            </Box>
            {addCSVOpen && (
                <>
                    <Alert severity='info' className='dropzone_info_alert'>
                        Upload a CSV of your Download our template to see the format.
                    </Alert>
                    {alertOpen && (
                        <Alert
                            className='dropzone_warning_alert'
                            severity='warning'
                            onClose={() => setAlertOpen(false)}
                        >
                            {alertMessage}
                        </Alert>
                    )}
                    <Box className='drop_and_template'>
                        <div
                            {...getRootProps()}
                            className={`drop-zone csv ${isDragActive ? 'active' : ''}`}
                        >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                                <p>Drop the files here ...</p>
                            ) : (
                                <Box>
                                    <CloudUpload className='download_csv_icon' />
                                    <p>Drag 'n' drop your CSV here, or click to select one</p>
                                </Box>
                            )}
                        </div>

                        <Button className='download_csv_button' onClick={exportTemplate}>
                            <DownloadForOffline className='download_csv_icon' />
                            <p className='download_text'>Download the template</p>
                        </Button>
                    </Box>
                </>
            )}{' '}
            <Box className='users_table'>
                <PassedMUIDataTable
                    addManualOpen={addManualOpen}
                    setAddManualOpen={setAddManualOpen}
                    data={userData}
                />
            </Box>
        </>
    );
}

export default ManageUsersTable;
