import React from 'react';
import { Box } from '@mui/material';

function RobotBox({ robot, classProp }) {
    return (
        <Box display={{ xs: 'none', md: 'flex' }} className={classProp ? classProp : 'robot_div'}>
            <img className='robot' src={robot} alt='Robot'></img>
        </Box>
    );
}

export default RobotBox;
