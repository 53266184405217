import { Typography } from '@mui/material';
import React from 'react';

function Header({ text }) {
    return (
        <Typography
            variant='h4'
            component='div'
            style={{ fontWeight: 600, margin: '1rem 0', zIndex: 9 }}
        >
            {text}
        </Typography>
    );
}

export default Header;
