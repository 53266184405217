import { Box, Checkbox, FormGroup, FormControlLabel, LinearProgress, Button } from '@mui/material';
import PassedAlert from '../../../components/Onboarding/PassedAlert';
import { useStoreState } from 'pullstate';
import { auth } from '../../../firebase';
import { sendEmailVerification } from 'firebase/auth';

import { OnboardingStore } from '../../../stores/OnboardingStore';
import WaveRobot from '../../../assets/images/WaveRobot.gif';
import RobotBox from '../../../components/Onboarding/RobotBox';
import Footer from '../../../components/Onboarding/Footer';
import OnboardingTextField from '../../../components/Onboarding/OnboardingTextField';
import EmptyRobotBox from '../../../components/Onboarding/EmptyRobotBox';
import OnboardingHeader from '../../../components/Onboarding/OnboardingHeader';
import { useState, useEffect } from 'react';

const Step3 = () => {
    const [emailSentAlert, setEmailSentAlert] = useState(false);
    const [emailVerifiedAlert, setEmailVerifiedAlert] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [checked, setChecked] = useState(false);
    const { userDoc } = useStoreState(OnboardingStore);
    const { firstName, lastName, email, jobTitle, institution } = userDoc;
    const title = 'Account Set Up';
    const subTitle = `  Welcome aboard, ${firstName}! \nLet's get to know each other before we get started.`;

    // email confirmation logic
    const verificationEmail = () => {
        const currentUser = auth.currentUser;
        if (currentUser.emailVerified) {
            setEmailVerifiedAlert(true);
            setEmailSentAlert(false);
        }
        if (currentUser) {
            sendEmailVerification(currentUser)
                .then(() => {
                    setEmailSentAlert(true);
                })
                .catch((error) => {
                    console.log('Error sending email verification:', error);
                    setEmailError(true);
                });
        } else {
            console.log(
                `No user is signed in, or the signed-in user's email does not match the input.`
            );
        }
    };

    useEffect(() => {
        const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
            if (user) {
                // User is signed in, check if email is verified
                if (user.emailVerified) {
                    setEmailVerifiedAlert(true);
                    setEmailSentAlert(false);
                }
            }
        });

        // Cleanup the observer on unmount
        return () => unregisterAuthObserver();
    }, []);

    return (
        <>
            <Box maxWidth={{ xs: '80%', md: '50rem' }} className='onboarding_step step_one active'>
                <Box textAlign={{ xs: 'center' }} className='onboarding_step3_container'>
                    <RobotBox robot={WaveRobot} />
                    <Box width={{ xs: '100%', md: '50rem' }} className='onboarding_step step_two'>
                        <LinearProgress
                            className='onboarding_progress'
                            variant='determinate'
                            value={33.33}
                        />

                        <OnboardingHeader title={title} subtitle={subTitle} step={1} />
                        <Box className='onboarding_step_content'>
                            <OnboardingTextField
                                id='firstName'
                                label='First Name'
                                placeholder='First Name'
                                defaultValue={firstName}
                                onChange={(e) => {
                                    OnboardingStore.update((s) => {
                                        s.userDoc.firstName = e.target.value;
                                    });
                                }}
                            />

                            <OnboardingTextField
                                id='lastName'
                                label='Last Name'
                                placeholder='Last Name'
                                defaultValue={lastName}
                                onChange={(e) => {
                                    OnboardingStore.update((s) => {
                                        s.userDoc.lastName = e.target.value;
                                    });
                                }}
                            />
                            <Box className='confirmEmail_box'>
                                <OnboardingTextField
                                    id='confirmEmail'
                                    label='Confirm Email'
                                    placeholder='Confirm Email'
                                    defaultValue={email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                />
                                <Button
                                    disabled={emailVerifiedAlert}
                                    className='confirmEmail_button'
                                    onClick={verificationEmail}
                                >
                                    Confirm
                                </Button>
                            </Box>
                            <Box>
                                {emailSentAlert && (
                                    <PassedAlert
                                        fire={emailSentAlert}
                                        severity='warning'
                                        message='Please check your email and verify your emailaddress'
                                    />
                                )}
                                {emailVerifiedAlert && (
                                    <PassedAlert
                                        fire={emailVerifiedAlert}
                                        severity='success'
                                        message='Success! Your email has been verified'
                                    />
                                )}
                                {emailError && (
                                    <PassedAlert
                                        fire={emailVerifiedAlert}
                                        severity='error'
                                        message={`We couldn't verify your email. Try to refresh the page and try again`}
                                    />
                                )}
                            </Box>
                            <OnboardingTextField
                                id='jobTitle'
                                label='Job Title'
                                placeholder='e.g. IT manager'
                                defaultValue={jobTitle}
                                onChange={(e) => {
                                    OnboardingStore.update((s) => {
                                        s.userDoc.jobTitle = e.target.value;
                                    });
                                }}
                            />
                            <OnboardingTextField
                                id='institution'
                                label='Institution'
                                placeholder='e.g. Passed.Ai Academy'
                                defaultValue={institution}
                                onChange={(e) => {
                                    OnboardingStore.update((s) => {
                                        s.userDoc.institution = e.target.value;
                                    });
                                }}
                            />
                        </Box>
                        <FormGroup>
                            <FormControlLabel
                                required
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(e) => setChecked(e.target.checked)}
                                    />
                                }
                                label='I am allowed to make decisions for this institution'
                            />
                        </FormGroup>
                    </Box>
                    <EmptyRobotBox />
                </Box>
                <Footer
                    disabled={
                        !firstName ||
                        !lastName ||
                        !emailVerifiedAlert ||
                        !jobTitle ||
                        !institution ||
                        !checked
                    }
                    nextStep={4}
                    backButton={false}
                />
            </Box>
        </>
    );
};

export default Step3;
