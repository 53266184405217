import { React, useState, useEffect } from 'react';

import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import LoadingButton from '@mui/lab/LoadingButton';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { CardStore } from '../../stores/CardStore';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { Save, ThumbUp, ThumbDown } from '@mui/icons-material';
import { Collapse, Box, Button, Divider, List, ListItem, ListItemText } from '@mui/material';
import StripeCards from './StripeCards.jsx';
import PaymentInfoText from './PaymentInfoText';
import Stripe from '../../assets/images/poweredByStripe.png';
import CircleChecked from '@mui/icons-material/CheckCircle';

const PaymentInfo = (props) => {
    const { userDoc } = useStoreState(AppStore);
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [showForm, setShowForm] = useState(!userDoc.selectedPaymentMethod);
    const [showPayment, setShowPayment] = useState(
        userDoc.active || !userDoc.selectedPaymentMethod
    );
    const [showConfirmation, setShowConfirmation] = useState(userDoc.selectedPaymentMethod);
    const [validForm, setValidForm] = useState(false);
    const [cardElementBlurred, setCardElementBlurred] = useState(false);
    const [paymentSuccess, setPaymentSuccess] = useState(false);

    const { formData } = useStoreState(CardStore);
    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        const requiredFields = ['name', 'email', 'line1', 'city', 'state', 'country'];
        setError(false);
        const isValid = requiredFields.every((field) => !!formData[field]);
        setValidForm(isValid);
    }, [formData]);

    const createSubscription = async () => {
        const response = await fetch(
            'https://us-central1-passedai-development.cloudfunctions.net/createStripeSubscription',
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ stripeId: userDoc.stripeId, quantity: 5 }),
            }
        );

        const result = await response.json();

        if (result.success) {
            alert('Subscription created successfully');

            AppStore.update((s) => {
                s.userDoc = { ...s.userDoc, active: true };
            });

            setPaymentSuccess(true);
            setShowConfirmation(false);
        } else {
            alert('Error creating subscription: ', result.error);
        }
    };

    const updateDoc = () => {
        setSaving(true);
        const docRef = doc(db, 'users', userDoc.uid);

        setDoc(docRef, userDoc, { merge: true })
            .then(() => {
                console.log('document uploaded successfully');
                setSaving(false);
                setSuccess(true);
            })
            .catch((error) => {
                setSaving(false);
                setError(true);
                console.log('error', error);
            });
    };

    // save info to firebase on submit
    const handleNewCard = async (e) => {
        e.preventDefault();
        if (!validForm) {
            return;
        }

        setSaving(true);
        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: formData.name,
                email: formData.email,
                address: {
                    line1: formData.line1,
                    line2: formData.line2,
                    city: formData.city,
                    state: formData.state,
                    country: formData.country,
                },
            },
        });

        if (error) {
            console.log('[error]', error);
            setSaving(false);
            setError(true);
            alert(error.message);
        } else {
            fetch(
                'https://us-central1-passedai-development.cloudfunctions.net/createStripeCustomer',
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        paymentMethodId: paymentMethod.id,
                        name: formData.name,
                        email: formData.email,
                        line1: formData.line1,
                        line2: formData.line2,
                        city: formData.city,
                        state: formData.state,
                        country: formData.country,
                        uid: userDoc.uid,
                    }),
                }
            )
                .then((response) => {
                    console.log('response', response);
                    return response.json();
                })
                .then((data) => {
                    AppStore.update((s) => {
                        s.userDoc = {
                            ...s.userDoc,
                            cardName: formData.name,
                            cardEmail: formData.email,
                            cardLine1: formData.line1,
                            cardLine2: formData.line2,
                            cardCity: formData.city,
                            cardState: formData.state,
                            cardCountry: formData.country,
                        };
                    });
                    CardStore.update((s) => {
                        s.selectedPaymentMethod = paymentMethod;
                        s.paymentSet = true;
                        //clear form
                        s.formData = {
                            name: '',
                            email: '',
                            line1: '',
                            line2: '',
                            city: '',
                            state: '',
                            zip: '',
                            country: '',
                        };
                    });
                    //collapse the form
                    setShowForm(false);
                    setSuccess(false);
                    updateDoc();
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
    };

    return (
        <form className='modal_container' onSubmit={handleNewCard}>
            {!userDoc.active && <h3 className='modal_title'>Set up subscription</h3>}
            <List>
                <ListItem
                    className={
                        userDoc.selectedPaymentMethod
                            ? 'modal_subtitle_container green_background'
                            : 'modal_subtitle_container'
                    }
                    button
                    onClick={() => {
                        setShowPayment(!showPayment);
                    }}
                >
                    <Box className='modal_subtitle_box'>
                        <ListItemText
                            primary={
                                !userDoc.active
                                    ? '1. Select Payment Option'
                                    : 'Select Payment Option'
                            }
                            primaryTypographyProps={{ className: 'modal_subtitle' }}
                        />
                        {userDoc.selectedPaymentMethod && (
                            <CircleChecked style={{ color: 'success' }} />
                        )}
                    </Box>
                </ListItem>
            </List>
            {!userDoc.active && <Divider />}
            <Collapse in={showPayment}>
                <StripeCards />
                {!showForm && (
                    <>
                        <Box>- OR -</Box>
                        <Button
                            variant='contained'
                            onClick={() => {
                                setShowForm(!showForm);
                                setShowConfirmation(true);
                            }}
                        >
                            Add a New Card
                        </Button>
                    </>
                )}
                <Collapse in={showForm}>
                    <PaymentInfoText />
                    <Box>
                        <CardElement
                            className='modal_cardElement'
                            onBlur={() => setCardElementBlurred(true)}
                        />
                    </Box>
                    <Box className='modal_button_box'>
                        <Button className='modal_cancel' onClick={() => setShowForm(!showForm)}>
                            Cancel
                        </Button>
                        <LoadingButton
                            disabled={saving || !validForm || !cardElementBlurred}
                            className='passed-btn'
                            style={{ margin: '1.5rem 0' }}
                            onClick={handleNewCard}
                            variant='contained'
                            loading={saving}
                            loadingPosition='start'
                            color={success ? 'success' : error ? 'error' : 'secondary'}
                            startIcon={success ? <ThumbUp /> : error ? <ThumbDown /> : <Save />}
                        >
                            Add Card
                        </LoadingButton>
                    </Box>
                    <Box className='modal_stripe_box'>
                        <img className='modal_stripe' alt='stripe' src={Stripe} />
                    </Box>
                </Collapse>
            </Collapse>
            {!userDoc.active && (
                <List>
                    <ListItem
                        className={
                            paymentSuccess
                                ? 'modal_subtitle_container green_background'
                                : 'modal_subtitle_container'
                        }
                        button
                        onClick={() => {
                            setShowConfirmation(!showConfirmation);
                            setShowPayment(false);
                        }}
                    >
                        <Box className='modal_subtitle_box'>
                            <ListItemText
                                primary='2. Confirm and pay now'
                                primaryTypographyProps={{
                                    className: 'modal_subtitle',
                                }}
                            />
                            {paymentSuccess && <CircleChecked style={{ color: 'success' }} />}
                        </Box>
                    </ListItem>
                </List>
            )}
            {!userDoc.active && <Divider />}
            {!userDoc.active && (
                <Collapse in={showConfirmation}>
                    <Box className='confirmation_container'>
                        <Box className='confirmation_box'>
                            <Box>
                                <Box style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>
                                    Standard Monthly Plan
                                </Box>

                                <Box style={{ marginBottom: '1rem', fontSize: '1.25rem' }}>
                                    5 Subscriptions
                                </Box>
                            </Box>
                            <Box className='confirmation_total'>
                                <Box>Unit price</Box>
                                <Box>$9.99</Box>
                            </Box>
                            <Box className='confirmation_total'>
                                <Box>Units</Box>
                                <Box>5</Box>
                            </Box>
                            <Box className='confirmation_total'>
                                <Box>Subtotal (USD)</Box>
                                <Box>$50</Box>
                            </Box>
                            <Box className='confirmation_total' style={{ color: 'red' }}>
                                <Box>School's out discount</Box>
                                <Box>-$20</Box>
                            </Box>
                            <Box className='confirmation_total'>
                                <Box>Total (USD)</Box>
                                <Box>$29.99</Box>
                            </Box>

                            <Button
                                variant='contained'
                                onClick={createSubscription}
                                style={{ marginTop: '1.5rem', marginBottom: '1.5rem' }}
                            >
                                Pay
                            </Button>
                            <Box className='modal_stripe_box'>
                                <img className='modal_stripe' alt='stripe' src={Stripe} />
                            </Box>
                        </Box>
                    </Box>
                </Collapse>
            )}
        </form>
    );
};

export default PaymentInfo;
