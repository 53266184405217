import {
    getAuth,
    isSignInWithEmailLink,
    onAuthStateChanged,
    signInWithEmailLink,
} from 'firebase/auth';
import { useStoreState } from 'pullstate';
import { useEffect, useState } from 'react';
import { AppStore } from './stores/AppStore';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';

import { auth, checkInstitutionStripeSubscription } from '../src/firebase';
import {
    getSubscriptions,
    getSetUserDoc,
    getPlans,
    getUsers,
    getInstitutionUid,
    updateCredits,
    activeSub,
} from './utils/getData';
import { createTheme, ThemeProvider } from '@mui/material';
import TagManager from 'react-gtm-module';
import Login from './Pages/Login';
import Home from './Pages/Home';
import Subscriptions from './Pages/Subscriptions/Subscriptions';
import Onboarding from './Pages/Onboarding/Onboarding';
import Help from './Pages/Help/Help';
import PrivateRoutes from './utils/PrivateRoutes';
import Navigation from './components/Navigation/Navigation';
import ScanNow from './Pages/ScanNow/ScanNow';
import Account from './Pages/Account/Account';
import Credits from './Pages/Credits/Credits';
import useMediaQuery from '@mui/material/useMediaQuery';
import MainPage from './components/MainPage/MainPage';
import Extension from './components/Extension/Extension';
import Goodbye from './Pages/Goodbye/Goodbye';
import ManageAccount from './Pages/ManageAccount/ManageAccount';
import ManageUsers from './Pages/ManageUsers/ManageUsers';
import { PassedLoader } from './components/MUI/PassedLoader';

import './App.css';
import NoGo from './components/NoGo/NoGo';

// GTM
const tagManagerArgs = {
    gtmId: 'GTM-M94H4PS',
};

TagManager.initialize(tagManagerArgs);
window.document.title = 'Passed.ai';

window.dataLayer.push({
    event: 'pageview',
});

function App() {
    const { userDoc, institutionDoc, loader, appBarHeight, drawerWidth } = useStoreState(AppStore);
    const [user, setUser] = useState(null);
    const [ready, setReady] = useState(false);

    const mobile = useMediaQuery('(max-width:650px)');
    const location = window.location.pathname;

    //create mui theme
    const passedTheme = createTheme({
        palette: {
            primary: {
                main: '#1f2641',
            },
            secondary: {
                main: '#FFC107',
            },
            tertiary: {
                main: '#e55934',
            },
            quaternary: {
                main: '#f0f0f0',
            },
            success: {
                main: '#4FB477',
                contrastText: '#fff',
            },
            danger: {
                main: '#D32F2F',
                contrastText: '#fff',
            },
        },
    });

    useEffect(() => {
        const fetchAdminSub = async () => {
            try {
                AppStore.update((s) => {
                    s.loader = true;
                });

                if (user && userDoc.ready) {
                    const result = await activeSub(user);
                    if (!result.hasActiveSub) {
                        const response = await checkInstitutionStripeSubscription({
                            adminUids: result.adminUids,
                        });
                        if (
                            response.data.status !==
                            'Subscription doc updated in Firebase and UID uploaded to Stripe'
                        ) {
                            alert(
                                "We couldn't find a subscription for your institution. Please contact us: hello@passed.ai"
                            );
                        }
                    }
                    await getSetUserDoc(user);
                    await getSubscriptions(user);
                }
            } catch (error) {
                const code = error.code;
                const message = error.message;
                const details = error.details;
                console.error(`Error occurred: ${code}, ${message}, ${details}`);
            } finally {
                AppStore.update((s) => {
                    s.loader = false;
                });
            }
        };

        fetchAdminSub();
    }, [user, userDoc.ready]);

    useEffect(() => {
        onAuthStateChanged(getAuth(), async (fbUser) => {
            AppStore.update((s) => {
                s.loader = true;
            });
            if (fbUser) {
                setUser(fbUser);
                AppStore.update((s) => {
                    s.user = fbUser;
                });
                getSetUserDoc(fbUser);
                getPlans();
                getUsers(fbUser);
                getSubscriptions(fbUser);
                updateCredits(fbUser);

                getInstitutionUid(fbUser);
            }
            //small delay just to show the loading screen
            //can be removed if we don't want to show the loading screen
            setTimeout(() => {
                setReady(true);
                AppStore.update((s) => {
                    s.loader = false;
                });
            }, 1000);
        });
    }, [user]);

    useEffect(() => {
        function getClientReferenceId() {
            return (
                (window.Rewardful && window.Rewardful.referral) ||
                'checkout_' + new Date().getTime()
            );
        }
        AppStore.update((s) => {
            s.clientReferenceId = getClientReferenceId();
        });
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                });
        }
    }, []);

    if (!ready || loader) {
        return <PassedLoader />;
    } else if (location === '/ext-scan') {
        return (
            <ThemeProvider theme={passedTheme}>
                <ScanNow />
            </ThemeProvider>
        );
    } else if (user && ready && !userDoc.ready) {
        return (
            <ThemeProvider theme={passedTheme}>
                <Onboarding />
            </ThemeProvider>
        );
    } else if (user && !institutionDoc.admin.includes(user.uid)) {
        return <NoGo />;
    } else {
        return (
            <>
                <PassedLoader />
                <ThemeProvider theme={passedTheme}>
                    <Router>
                        {!user && <MainPage content={<Login />} />}

                        {user && userDoc.admin && (
                            <>
                                <Navigation />

                                <div
                                    className='app_content'
                                    //conditionals in style are to accommodate login page
                                    style={{
                                        maxWidth: '100vw',
                                        height: '100vh',
                                        marginLeft:
                                            !mobile && userDoc.uid
                                                ? `${drawerWidth}px`
                                                : !mobile && !userDoc.uid
                                                ? 0
                                                : mobile
                                                ? 0
                                                : `${drawerWidth}px`,
                                        marginTop:
                                            !mobile && userDoc.uid
                                                ? `calc(${appBarHeight}px + 24px)`
                                                : !mobile && !userDoc.uid
                                                ? 0
                                                : mobile
                                                ? 0
                                                : `calc(${appBarHeight}px + 24px)`,
                                    }}
                                >
                                    <div>
                                        <Routes>
                                            <Route element={<PrivateRoutes user={user} />}>
                                                <Route
                                                    path='/dashboard'
                                                    element={<MainPage content={<Home />} />}
                                                />
                                                <Route
                                                    path='/manage-users'
                                                    element={<MainPage content={<ManageUsers />} />}
                                                />
                                                <Route
                                                    path='/subscribe'
                                                    element={
                                                        <MainPage content={<Subscriptions />} />
                                                    }
                                                />
                                                <Route
                                                    path='/account'
                                                    element={<MainPage content={<Account />} />}
                                                />
                                                <Route
                                                    path='/help'
                                                    element={<MainPage content={<Help />} />}
                                                />
                                                <Route
                                                    path='/credits'
                                                    element={<MainPage content={<Credits />} />}
                                                />
                                                <Route
                                                    path='/extension'
                                                    element={<MainPage content={<Extension />} />}
                                                />
                                                <Route
                                                    path='/manage-account'
                                                    element={
                                                        <MainPage content={<ManageAccount />} />
                                                    }
                                                />
                                                <Route
                                                    path='/'
                                                    element={
                                                        <Navigate
                                                            to='/dashboard'
                                                            element={
                                                                <MainPage content={<Home />} />
                                                            }
                                                        />
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path='/login'
                                                element={
                                                    <MainPage content={<Login user={user} />} />
                                                }
                                            />
                                            <Route
                                                path='/goodbye'
                                                element={<MainPage content={<Goodbye />} />}
                                            />
                                        </Routes>
                                    </div>
                                </div>
                            </>
                        )}
                    </Router>
                </ThemeProvider>
            </>
        );
    }
}

export default App;
