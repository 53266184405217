import './Help.css';
import { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import HelpSection from '../../components/Help/HelpSection';
import HelpVideos from '../../components/Help/HelpVideos';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, useSearchBox, Hits } from 'react-instantsearch-hooks-web';
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Container,
    InputAdornment,
    TextField,
    Box,
} from '@mui/material';
import { ExpandMore, Search } from '@mui/icons-material';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../components/MUI/Header';
import EmailFab from '../../components/MUI/EmailFab';
const searchClient = algoliasearch('FCJV1AEH0E', 'c588975c9ecb94569bc05001d7433118');

const CustomSearchBox = (props) => {
    const { refine, currentRefinement } = useSearchBox(props);

    return (
        <Container maxWidth='md' direction='column' sx={{ mt: 5, mb: 5, textAlign: 'center' }}>
            <Header text='How can we help?' />
            <TextField
                id='standard-search'
                label='Search our FAQs'
                type='search'
                // variant='standard'
                value={currentRefinement}
                sx={{ width: '100%' }}
                // Disabled; re-enable if you only want to show hits when the search box is focused
                // onFocus={() => props.onFocus(true)}
                // onBlur={() => props.onBlur(false)}
                onChange={(e) => {
                    refine(e.target.value);
                    props.onInputChange(e.target.value);
                }}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position='end'>
                            <Search />
                        </InputAdornment>
                    ),
                }}
            />
        </Container>
    );
};

const Help = () => {
    const [showHits, setShowHits] = useState(false);
    const [helpText, setHelpText] = useState([]);

    const mobile = useMediaQuery('(max-width:650px)');

    // only show hits if the query is not empty
    const handleInputChange = (value) => {
        setShowHits(value.length > 0);
    };

    const getHelp = async () => {
        const querySnapshot = await getDocs(collection(db, 'help', 'app', 'instructions'));
        const helpArray = [];
        querySnapshot.forEach((doc) => {
            helpArray.push(doc.data());
        });
        setHelpText(helpArray);
    };
    useEffect(() => {
        getHelp();
    }, []);

    return (
        <Box className='help-page'>
            <InstantSearch searchClient={searchClient} indexName='passed_help'>
                <div id='help-container'>
                    <CustomSearchBox
                        // onBlur={setShowHits}
                        // onFocus={setShowHits}
                        onInputChange={handleInputChange}
                    />
                    {showHits ? <Hits hitComponent={Hit} /> : null}

                    <Accordion style={{ marginBottom: '1rem', width: '100%' }}>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                        >
                            <h3>How-To Videos</h3>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: '0' }}>
                            <HelpVideos />
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls='panel2a-content'
                            id='panel2a-header'
                        >
                            <h3>Instructions</h3>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: mobile ? '0' : '' }}>
                            <HelpSection title='Instructions' contents={helpText} isFAQ='false' />
                        </AccordionDetails>
                    </Accordion>

                    {/* <HelpSection title='FAQs' contents={FAQs} isFAQ='true' /> */}
                </div>
            </InstantSearch>
            <EmailFab />
        </Box>
    );
};

function Hit({ hit }) {
    return (
        <article>
            <h1>{hit.q}</h1>
            <p>{hit.a}</p>
        </article>
    );
}

export default Help;
