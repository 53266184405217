import React from 'react';
import { Typography } from '@mui/material';

function OnboardingHeader({ title, subtitle, step }) {
    return (
        <>
            {step && <p className='step_number'>STEP {step} OF 3</p>}
            <Typography
                fontSize={{ xs: '1.5rem', md: '2.5rem' }}
                mb={{ xs: '1rem', md: '1.5rem' }}
                mt={{ xs: '0.5rem', md: '1rem' }}
                fontWeight='bold'
            >
                {title}
            </Typography>
            <Typography
                className='onboarding_text'
                fontSize={{ xs: '1.15rem', md: '2rem' }}
                mb={{ xs: '1rem', md: '1.5rem' }}
                fontWeight='600'
            >
                {subtitle}
            </Typography>
        </>
    );
}

export default OnboardingHeader;
