import { Divider, Box, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import { useStoreState } from 'pullstate';
import { db } from '../../../firebase';
import { OnboardingStore } from '../../../stores/OnboardingStore';
import { AppStore } from '../../../stores/AppStore';
import { collection, doc, setDoc, addDoc, onSnapshot } from 'firebase/firestore';
import Demo from '../../../assets/images/demo1.gif';
import HttpsTwoToneIcon from '@mui/icons-material/HttpsTwoTone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardDoubleArrowDownOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowDownOutlined';
import DoneSharpIcon from '@mui/icons-material/DoneSharp';
import Stripe from '../../../assets/images/stripe.png';
import { getSetUserDoc } from '../../../utils/getData';
import OnboardingHeader from '../../../components/Onboarding/OnboardingHeader';

const Step2 = () => {
    const { clientReferenceId } = useStoreState(OnboardingStore);
    const { subscriptions, userDoc } = useStoreState(AppStore);
    const [loading, setLoading] = useState(false);
    const matchesXs = useMediaQuery('(min-width:899px)');
    const title = 'Set up your subscription';
    const subtitle = 'Elevate your teaching game. Sign up now and experience the difference!';

    // console.log('userDoc', userDoc);
    const features = [
        'Document Audit: Get all the important stats',
        'History Overview: Check out who contributed',
        'Replay Tool: Watch the document creation',
        'AI Detection',
        'Get the complete picture',
        'Minimize false negatives',
    ];

    const setPaymentStarted = async () => {
        try {
            const cartDocRef = doc(db, 'users', userDoc.uid);
            const userData = { paymentStarted: true };
            await setDoc(cartDocRef, userData, { merge: true });
        } catch (error) {
            console.error(error);
        }
    };

    const startSubscription = () => {
        setLoading(true);
        const subscribe = async () => {
            try {
                const docRef = await addDoc(
                    collection(db, 'users', userDoc.uid, 'checkout_sessions'),
                    {
                        // price: 'price_1N3TkfGJ1Yvc8jVWKSwyOHFm',
                        price: 'price_1N5Z6uGJ1Yvc8jVWygCy2DaO',
                        trial_from_plan: subscriptions?.length ? false : true,
                        success_url: window.location.href + '?success=true',
                        cancel_url: window.location.href + '?cancelled=true',
                        allow_promotion_codes: true,
                        collect_shipping_address: false,
                        client_reference_id: clientReferenceId || '',
                    }
                );

                // Wait for the CheckoutSession to get attached by the extension
                onSnapshot(docRef, (snap) => {
                    const { error, url } = snap.data();
                    console.log('error', error);

                    if (error) {
                        // Show an error to your customer and
                        // inspect your Cloud Function logs in the Firebase console.
                        console.log(`An error occurred: ${error.message}`);
                    }
                    if (url) {
                        //first set the paymentStarted flag to true in Firestore
                        setPaymentStarted();
                        // We have a Stripe Checkout URL, let's redirect.
                        window.location.assign(url);
                        setLoading(false);
                    }
                });
            } catch (error) {
                console.error('Error inside subscribe function:', error);
                //try again
                getSetUserDoc(userDoc).then(() => {
                    startSubscription();
                });
            }
        };

        subscribe();
    };
    return (
        <Box maxWidth={{ xs: '80%', md: '50rem' }} className='onboarding_step step_one active'>
            <Box maxWidth={{ xs: '100%', md: '50rem' }} className='onboarding_step step_two'>
                <OnboardingHeader title={title} subtitle={subtitle} />

                <Box
                    flexDirection={{ xs: 'column-reverse', md: 'row-reverse' }}
                    className='onboarding_payment_container'
                    min-width={{ md: '60rem' }}
                >
                    <div className='onboarding_payment_box'>
                        <Typography
                            className='onboarding_text light'
                            fontSize={{ xs: '1.25rem', md: '1.5rem' }}
                        >
                            Teach smarter, not harder - risk-free!
                        </Typography>
                        <Typography
                            className='onboarding_text light'
                            fontSize={{ xs: '1.25rem', md: '1.5rem' }}
                        >
                            <strong> 14-day free trial + 100 free credits </strong>
                        </Typography>
                        <KeyboardDoubleArrowDownOutlinedIcon className='xl-icon' />
                        <LoadingButton
                            sx={{
                                width: ['0.8', '0.8', '1'],
                            }}
                            loading={loading}
                            loadingPosition='center'
                            variant='contained'
                            color='success'
                            className='payment_button'
                            onClick={() => {
                                startSubscription();
                            }}
                        >
                            Subscribe now for $9.99
                            <ArrowForwardIosIcon fontSize='large' style={{ color: '#d9d7d7' }} />
                        </LoadingButton>
                        <div className='onboarding_note_container'>
                            <img src={Stripe} alt='Stripe' className='stripe_logo' />
                            <p className='onboarding_note'>
                                <HttpsTwoToneIcon fontSize='small' />
                                Secure Checkout
                            </p>
                        </div>
                    </div>

                    <Divider
                        orientation={matchesXs ? 'horizontal' : 'vertical'}
                        variant='middle'
                        flexItem
                    />
                    <div className='onboarding_payment_box'>
                        <Typography
                            fontSize={{ xs: '1.25rem', md: '1.5rem' }}
                            className='onboarding_text light'
                        >
                            Just a few of the things you'll be able to do:
                        </Typography>
                        <img src={Demo} alt='' className='gif'></img>
                        <div className='feature_container'>
                            {features.map((feature, i) => (
                                <div className='feature_list' key={i}>
                                    <DoneSharpIcon />
                                    <Typography key={i} className='onboarding_text extralight'>
                                        {feature}
                                    </Typography>
                                </div>
                            ))}
                        </div>
                    </div>
                </Box>
            </Box>
        </Box>
    );
};

export default Step2;
