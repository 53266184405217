import { React, useEffect } from 'react';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';

import '../../App.css';

function Goodbye() {
    const { userDoc } = useStoreState(AppStore);

    const queryParameters = new URLSearchParams(window.location.search);
    const email = queryParameters.get('user');
    const reason = queryParameters.get('reason');

    useEffect(() => {
        if (email && reason) {
            // get firebase doc matching id from gDocs collection if it exists
            fetch(
                `https://us-central1-passedai-development.cloudfunctions.net/getTrialCancellationReason`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        user: email,
                        reason: reason,
                    }),
                }
            );
        }
        // console.log(fid, id);
    }, [email, reason]);

    return (
        <div className={`logo-container ${userDoc.active ? 'dark' : ''}`}>
            <p className='login-blurb'>
                Oh, you're leaving us? We'll try not to take it personally... *sniffles*.
            </p>
            <p className='login-blurb'>
                But don't worry, we'll be improving our service until you can't resist coming back!
            </p>
        </div>
    );
}

export default Goodbye;
