import { useStoreState } from 'pullstate';
import { Fragment } from 'react';
import { CurrentScan } from '../../stores/CurrentScan';
// import Legend from './Legend/Legend';
import './Results.css';

const Results = () => {
    const { results } = useStoreState(CurrentScan);

    return (
        <div className='Results-container'>
            {/* <Legend /> */}
            <div className='Results' id='resultsContainer'>
                {results?.result?.scan_results?.aiResults?.blocks?.map((block, i) => {
                    return (
                        <div
                            key={i}
                            className={`block ${
                                block.result.fake > 0.75
                                    ? 'red'
                                    : block.result.fake > 0.4
                                    ? 'pink'
                                    : 'green'
                            }`}
                            style={{ '--strength': block.result.fake }}
                        >
                            {block.text.split('\n').map((line, j, arr) => {
                                return (
                                    <Fragment key={j}>
                                        {i !== 0 && j === 0 && <div className='spacer' />}
                                        <span className='line'>{line}</span>
                                        {j !== arr.length - 1 && <br />}
                                    </Fragment>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default Results;
