import { Box, Button, Typography, LinearProgress } from '@mui/material';
import { useStoreState } from 'pullstate';
import { OnboardingStore } from '../../../stores/OnboardingStore';
import DetectiveRobot from '../../../assets/images/DetectiveRobot.gif';
import University from '../../../assets/images/uni.png';
import Highschool from '../../../assets/images/highschool.png';

import OnboardingHeader from '../../../components/Onboarding/OnboardingHeader';
import RobotBox from '../../../components/Onboarding/RobotBox';
import Footer from '../../../components/Onboarding/Footer';
import EmptyRobotBox from '../../../components/Onboarding/EmptyRobotBox';

const Step4 = () => {
    const { userDoc } = useStoreState(OnboardingStore);
    // console.log('userDocstep4', userDoc);
    const { level } = userDoc;
    const title = 'Institution Profile';
    const subTitle = `Help us help you shine! \nTell us a little bit about how you'll use Passed.ai.`;

    return (
        <Box maxWidth={{ xs: '80%', md: '50rem' }} className='onboarding_step step_one active'>
            <Box textAlign={{ xs: 'center' }} className='onboarding_step3_container'>
                <EmptyRobotBox />
                <Box width={{ xs: '100%', md: '50rem' }} className='onboarding_step step_two'>
                    <LinearProgress
                        className='onboarding_progress'
                        variant='determinate'
                        value={66.66}
                    />
                    <OnboardingHeader title={title} subtitle={subTitle} step={2} />

                    <Box className='onboarding_step4_content'>
                        <Button
                            className='level_box'
                            onClick={() => {
                                OnboardingStore.update((s) => {
                                    s.userDoc.level = 'highSchool';
                                });
                            }}
                        >
                            <img className='onboarding_img' alt='high school' src={Highschool} />
                            <Typography>High School</Typography>
                        </Button>
                        <Button
                            className='level_box'
                            onClick={() => {
                                OnboardingStore.update((s) => {
                                    s.userDoc.level = 'university';
                                });
                            }}
                        >
                            <img
                                className='onboarding_img'
                                alt='university school'
                                src={University}
                            />
                            <Typography>University</Typography>
                        </Button>
                    </Box>
                </Box>
                <RobotBox robot={DetectiveRobot} />
            </Box>
            <Footer disabled={!level} nextStep={6} previousStep={3} backButton={true} />
        </Box>
    );
};

export default Step4;
