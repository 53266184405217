import { React, useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import { Autocomplete } from '@mui/lab';
import { CardStore } from '../../stores/CardStore';

import './PaymentInfo.css';
import { useStoreState } from 'pullstate';

const PaymentInfoText = () => {
    const [showImage, setShowImage] = useState(true);
    const noFlagCountries = [
        'BV',
        'UM',
        'IO',
        'SJ',
        'BL',
        'HM',
        'GP',
        'PM',
        'XK',
        'BQ',
        'SX',
        'GF',
    ]; // Add the country codes that should not display a flag here

    const handleImageError = () => {
        setShowImage(false);
    };

    const { formData } = useStoreState(CardStore);

    const [countries, setCountries] = useState([]);

    useEffect(() => {
        const fetchCountries = async () => {
            const response = await fetch('https://restcountries.com/v3.1/all');
            const data = await response.json();
            const fetchedCountries = data.map((country) => ({
                name: country.name.common,
                code: country.cca2,
            }));
            setCountries(fetchedCountries);
        };

        fetchCountries();
    }, []);

    const handleChange = (e) => {
        CardStore.update((s) => {
            s.formData = { ...s.formData, [e.target.name]: e.target.value };
        });
    };

    const topCountries = countries.filter(
        (country) => country.code === 'CA' || country.code === 'US'
    );
    const otherCountries = countries.filter(
        (country) => country.code !== 'CA' && country.code !== 'US'
    );

    const sortedCountries = [...topCountries, ...otherCountries];

    return (
        <>
            {Object.entries(formData)
                .filter(([key]) => key !== 'zip' && key !== 'country')
                .map((element, i) => (
                    <TextField
                        className='modal_textfield'
                        key={i}
                        name={element[0]}
                        label={
                            element[0] === 'line1'
                                ? 'Address Line 1'
                                : element[0] === 'line2'
                                ? 'Address Line 2'
                                : element[0].charAt(0).toUpperCase() + element[0].slice(1)
                        }
                        value={element[1]}
                        onChange={handleChange}
                        placeholder={element[0]}
                        required
                        size='small'
                    />
                ))}
            <Autocomplete
                className='modal_textfield'
                options={sortedCountries}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) =>
                    handleChange({ target: { name: 'country', value: value ? value.code : '' } })
                }
                renderOption={(props, option) => (
                    <li {...props}>
                        {showImage && !noFlagCountries.includes(option.code) && (
                            <img
                                src={`https://flagsapi.com/${option.code}/shiny/24.png`}
                                alt='country-flag'
                                lt='country-flag'
                                className='modal_flag'
                                onError={handleImageError}
                            />
                        )}

                        {option.name}
                    </li>
                )}
                renderInput={(params) => (
                    <TextField {...params} name='country' label='Country' required size='small' />
                )}
            />
        </>
    );
};

export default PaymentInfoText;
