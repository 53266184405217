import { PacmanLoader } from 'react-spinners';
import './Scanning.css';

const Scanning = () => {
    return (
        <div className='Scanning'>
            <h2>Scanning...</h2>
            <PacmanLoader color='#1f2641' />
        </div>
    );
};

export default Scanning;
