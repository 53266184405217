import { logout } from '../../firebase';
import PassedButton from '../MUI/PassedButton';
import './NoGo.css';

const NoGo = () => {
    return (
        <div className='NoGo'>
            <img
                src='https://firebasestorage.googleapis.com/v0/b/passedai.appspot.com/o/app%2Fyou-didnt-say-the-magic-word-ah-ah.gif?alt=media&token=e6797128-56c6-4640-b8df-695b4ebb3d1b'
                alt='Error'
            />
            <p className='NoGo-text'>It doesn't look like you should be here.</p>
            <p>
                Before the Dilophosaurus arrives, please log out and log back in with an
                administrator account.
            </p>
            <PassedButton
                text='Log Out'
                onClick={() => {
                    logout();
                }}
            />
        </div>
    );
};

export default NoGo;
