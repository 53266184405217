import './ChartLabel.css';

const ChartLabel = ({ label }) => {
    return (
        <div className='ChartLabel'>
            <div className='ChartLabel__label'>{label}</div>
        </div>
    );
};

export default ChartLabel;
