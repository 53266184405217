//pullstate store

import { Store } from 'pullstate';

export const CardStore = new Store({
    formData: {
        name: '',
        email: '',
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    },
    selectedPaymentMethod: {},
    paymentSet: false,
});
