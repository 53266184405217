import { React, useState, useEffect } from 'react';

import { Checkbox, List, ListItem, ListItemIcon, Typography } from '@mui/material';
import CircleChecked from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { CardStore } from '../../stores/CardStore';

import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const StripeCards = () => {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const { userDoc } = useStoreState(AppStore);
    const { selectedPaymentMethod, paymentSet } = useStoreState(CardStore);

    const updatePaymentMethod = async () => {
        const response = await fetch(
            'https://us-central1-passedai-development.cloudfunctions.net/updateStripePaymentMethod',
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    stripeId: userDoc.stripeId,
                    paymentMethodId: selectedPaymentMethod.id,
                    uid: userDoc.uid,
                }),
            }
        );

        const result = await response.json();

        if (result.error) {
            console.error(result.error);
        } else {
            console.log(result.result);
        }
    };

    const handleToggle = (method) => {
        CardStore.update((s) => {
            s.selectedPaymentMethod = method;
        });
        AppStore.update((s) => {
            s.userDoc = { ...s.userDoc, selectedPaymentMethod: method };
        });
        if (userDoc.stripeId) {
            updatePaymentMethod();
        }
    };

    useEffect(() => {
        const fetchPaymentMethods = async () => {
            const paymentMethodsCollection = collection(db, 'users', userDoc.uid, 'paymentInfo');
            const paymentMethodsSnapshot = await getDocs(paymentMethodsCollection);
            const paymentMethods = paymentMethodsSnapshot.docs.map((doc) => doc.data());
            setPaymentMethods(paymentMethods);
        };

        fetchPaymentMethods();

        CardStore.update((s) => {
            s.paymentSet = false;
        });
    }, [userDoc.uid, paymentSet]);

    return (
        <>
            <List className='modal_list'>
                {paymentMethods.map((method, i) => (
                    <ListItem
                        key={i}
                        role={undefined}
                        dense
                        button
                        onClick={() => handleToggle(method)}
                    >
                        <ListItemIcon>
                            <Checkbox
                                icon={<CircleUnchecked />}
                                checkedIcon={<CircleChecked />}
                                edge='start'
                                checked={userDoc.selectedPaymentMethod.id === method.id}
                                tabIndex={-1}
                                disableRipple
                            />
                        </ListItemIcon>
                        <CreditCardIcon />
                        <Typography className='modal_list_brand'>
                            {method.card.brand.toUpperCase()}
                        </Typography>
                        <Typography>{`**** **** **** ${method.card.last4}`}</Typography>
                    </ListItem>
                ))}
            </List>
        </>
    );
};

export default StripeCards;
