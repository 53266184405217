import { React, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Fade, Typography, Modal, TextField, Alert, Button } from '@mui/material';
import { Save, ThumbUp, ThumbDown } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import { doc, setDoc, getDocs, query, collection, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';

import './AdminModal.css';

export const AdminModal = ({ open, setAddEmail, adminEmails, setAdminEmails }) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [actionBtn, setActionBtn] = useState(false);
    const [adminEmailInput, setAdminEmailInput] = useState('');
    const navigate = useNavigate();

    const { institutionDoc } = useStoreState(AppStore);

    const addAdmin = async (email) => {
        try {
            setLoading(true);
            const checkEmail = query(
                collection(db, 'users'),
                where('email', '==', email),
                where('institutionId', '==', institutionDoc.institutionId)
            );
            const querySnapshot = await getDocs(checkEmail);

            if (querySnapshot.empty) {
                setLoading(false);
                setError(true);
                setErrorMsg('No user with this email found in your institution');
                setActionBtn(true);
                return;
            }

            const adminUid = querySnapshot.docs[0].id;
            // console.log('querySnapshot', querySnapshot.docs[0].data());
            if (querySnapshot.docs[0].data().admin) {
                setLoading(false);
                setError(true);
                setErrorMsg('This user is already an admin');
                return;
            }
            const newAdmins = [...institutionDoc.admin, adminUid];
            const newAdminEmails = [...adminEmails, email];
            const userRef = doc(db, 'users', adminUid);
            const institutionRef = doc(db, 'institutions', institutionDoc.institutionId);

            //adding the admin flag to the user in firebase
            await setDoc(userRef, { admin: true }, { merge: true });
            await setDoc(institutionRef, { admin: newAdmins }, { merge: true });

            //changing the admin state locally
            AppStore.update((s) => {
                const userToUpdate = s.users.find((u) => u.email === email);
                if (userToUpdate) {
                    userToUpdate.admin = true;
                }
                s.institutionDoc = {
                    ...s.institutionDoc,
                    admin: newAdmins,
                };
            });
            //changing the admin list
            setAdminEmails(newAdminEmails);

            setSuccess(true);
            setLoading(false);
            setTimeout(() => {
                setAddEmail(false);
            }, 500);
        } catch (error) {
            console.error('Error adding admin:', error);
            setError(true);
            setErrorMsg(error.message || 'An error occurred.');
            setLoading(false); // End loading state
        }
    };

    return (
        <>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={open}
                onClose={() => setAddEmail(false)}
                closeAfterTransition
                // slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500,
                    },
                }}
            >
                <Fade in={true}>
                    <Box className='modal-container'>
                        <Typography id='modal-title' variant='h6' component='h2'>
                            Add An Administrator
                        </Typography>
                        <Typography className='modal-text'>
                            Please type in the email address of the administrator you'd like to add.
                            They have to be listed as a user first.
                        </Typography>

                        <TextField
                            className='modal-textfield'
                            placeholder='Type Admin Email Here'
                            name='adminEmail'
                            onChange={(e) => setAdminEmailInput(e.target.value)}
                            onFocus={() => {
                                setError(false);
                                setErrorMsg('');
                                setSuccess(false);
                            }}
                        />
                        {error && (
                            <Alert
                                severity='error'
                                className='modal-alert'
                                action={
                                    actionBtn ? (
                                        <Button
                                            color='inherit'
                                            size='small'
                                            onClick={() => {
                                                navigate('/manage-users');
                                            }}
                                        >
                                            Add User
                                        </Button>
                                    ) : null
                                }
                            >
                                {errorMsg
                                    ? errorMsg
                                    : `We've encountered an error. Please try again later.`}
                            </Alert>
                        )}
                        <Box className='modal-buttons'>
                            <LoadingButton
                                className='passed-btn'
                                variant='contained'
                                disabled={loading}
                                color={error ? 'error' : success ? 'success' : 'secondary'}
                                loading={loading}
                                loadingPosition='start'
                                style={{ margin: '1.5rem' }}
                                startIcon={success ? <ThumbUp /> : error ? <ThumbDown /> : <Save />}
                                onClick={() => {
                                    addAdmin(adminEmailInput);
                                }}
                            >
                                Add Admin
                            </LoadingButton>
                        </Box>
                    </Box>
                </Fade>
            </Modal>
            );
        </>
    );
};
