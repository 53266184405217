//pullstate store

import { Store } from 'pullstate';

export const AppStore = new Store({
    user: null,
    userDoc: {
        ready: false,
    },
    institutionDoc: {},
    drawerWidth: 280,
    appBarHeight: 64,
    plans: null,
    clientReferenceId: null,
    users: [],
    loader: false,
});
