import React from 'react';
import { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { collection, getDocs } from 'firebase/firestore';
import { Card } from '@mui/material';
import './HelpCard.css';
import './HelpVideos.css';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function Learn() {
    const [vids, setVids] = useState([]);
    const mobile = useMediaQuery('(max-width:650px)');

    const getVids = async () => {
        const querySnapshot = await getDocs(collection(db, 'help', 'app', 'videos'));
        const vidArray = [];
        querySnapshot.forEach((doc) => {
            vidArray.push(doc.data());
        });
        setVids(vidArray);
    };

    useEffect(() => {
        getVids();
    }, []);

    return (
        <div className='card-container'>
            <div className='card-bottom'>
                <p>
                    Our library of tutorials is being created as you read this. The goal of the
                    videos below is to guide you through your Passed.ai experience. Is there
                    anything we should add? Let us know in the chat below!
                </p>
            </div>
            {vids.map((element, i) => {
                return (
                    <Card
                        key={i}
                        className='video-card'
                        style={{
                            display: 'flex',
                            flexDirection: mobile ? 'column' : 'row',
                        }}
                    >
                        <iframe
                            width={mobile ? '100%' : '320'}
                            height='auto'
                            src={element.src}
                            title='YouTube video player'
                            frameBorder='0'
                            allowFullScreen
                        ></iframe>
                        <div className='video-info'>
                            <h2>{element.title}</h2>
                            <p>{element.info}</p>
                        </div>
                    </Card>
                );
            })}
        </div>
    );
}
