import { React, useState, useEffect } from 'react';

import { Box, TextField, Button, Modal } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import PassedButton from '../MUI/PassedButton';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';

import { updateMainUserCreditsByEmail, changeCredits } from '../../utils/getData';

function PassedModal({ open, user, setOpenModal }) {
    const [credits, setCredits] = useState(Number(user.credits || 0));
    const [initialCredits, setInitialCredits] = useState(Number(user.credits));

    const { institutionDoc } = useStoreState(AppStore);

    useEffect(() => {
        setCredits(Number(user.credits || 0));
        setInitialCredits(Number(user.credits || 0));
    }, [user]);

    const style = {
        width: '30%',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'white',
        padding: '1rem',
        borderRadius: '10px',
        textAlign: 'center',
        maxHeight: '90vh',
        overflowY: 'auto',
        opacity: open ? 1 : 0,
        transition: 'opacity 0.3s ease-in-out',
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpenModal(false)}
            aria-labelledby='modal-assign-credits'
            aria-describedby='modal-assign-credits-to-a-user'
        >
            <Box sx={style}>
                <Box>
                    Change credits for {user.email} to:
                    <Box className='MU_box'>
                        <Button
                            className='MU_button arrow'
                            onClick={() => {
                                setCredits((prevCredits) => Math.max(prevCredits + 5, 0));
                            }}
                        >
                            <ArrowDropUp className='MU_icon' />
                        </Button>
                        <TextField
                            value={credits || 0}
                            onChange={(e) => setCredits(Number(e.target.value))}
                            inputProps={{
                                style: {
                                    padding: '0.25rem',
                                    width: '2rem',
                                    textAlign: 'center',
                                },
                            }}
                        />
                        <Button
                            className='MU_button arrow'
                            onClick={() => {
                                setCredits((prevCredits) => Math.max(prevCredits - 5, 0));
                            }}
                        >
                            <ArrowDropDown className='MU_icon' />
                        </Button>
                    </Box>
                    <PassedButton
                        text='confirm'
                        onClick={async () => {
                            const creditDifference = credits - initialCredits;

                            // Check if the institution has enough credits to cover this
                            if (creditDifference > institutionDoc.credits) {
                                alert('Not enough available credits to assign the new value.');
                                return;
                            } else {
                                await changeCredits(
                                    institutionDoc.institutionId,
                                    -creditDifference
                                );
                                updateMainUserCreditsByEmail(user.email, credits);
                                setOpenModal(false);
                            }
                        }}
                    />
                </Box>
            </Box>
        </Modal>
    );
}

export default PassedModal;
