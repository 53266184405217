import React from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';

function OnboardingBottomGrid({ srcName }) {
    return (
        <Grid xs={4} className='onboarding_text_grid'>
            <strong>{srcName}</strong>
        </Grid>
    );
}

export default OnboardingBottomGrid;
