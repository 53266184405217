import { CircularProgress } from '@mui/material';
import { useStoreState } from 'pullstate';
import { useEffect, useState } from 'react';
import { CurrentScan } from '../../../stores/CurrentScan';
import ResultsTabs from '../../Results/ResultsTabs/ResultsTabs';
import './SideBarResults.css';

const SideBarResults = () => {
    const { results } = useStoreState(CurrentScan);
    const [resultType, setResultType] = useState(null);
    useEffect(() => {
        if (results) {
            // console.log('results', results);
            setResultType(results?.scan_results?.plagResults ? 'Plagiarism Scan' : 'API Scan');
        }
    }, [results]);

    if (resultType) {
        return (
            <div className='SideBarResults'>
                <h2>Results</h2>
                <ResultsTabs />
            </div>
        );
    } else {
        return (
            <div className='SideBarResults'>
                <CircularProgress />
            </div>
        );
    }
};

export default SideBarResults;
