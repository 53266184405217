import HelpCard from './HelpCard';
import HelpTOC from './HelpTOC';
import './HelpSection.css';
import { useState, useEffect } from 'react';

const HelpSection = (props) => {
    const [cards, setCards] = useState([]);
    const [isFAQ] = useState(props.isFAQ === 'true' ? true : false);
    useEffect(() => {
        const cardList = [];
        props.contents.forEach((element) => {
            let item = {};
            item.header = isFAQ ? (
                <>
                    <b>Q:&nbsp;&nbsp;</b>
                    {element.q}
                </>
            ) : (
                <>{element.headline}</>
            );
            item.body = isFAQ ? (
                <p>
                    <b>A:&nbsp;&nbsp;</b>
                    {element.a}
                </p>
            ) : (
                <>
                    {element.text.map((element, i) => {
                        return <p key={i}>{element}</p>;
                    })}
                </>
            );
            cardList.push(item);
        });
        setCards(cardList);
    }, [props, isFAQ]);
    return (
        <div className='section'>
            <div className='section-title'>{/* <h1 id={props.title}>{props.title}</h1> */}</div>
            {!isFAQ && <HelpTOC cards={cards} />}

            <div className='section-body'>
                {cards.map((element, i) => {
                    return (
                        <HelpCard
                            key={(isFAQ === true ? 'FAQ-' : 'help-') + i.toString()}
                            id={(isFAQ === true ? 'FAQ-' : 'help-') + i.toString()}
                            header={element.header}
                            body={element.body}
                        />
                    );
                })}
            </div>
        </div>
    );
};
export default HelpSection;
