import { React } from 'react';
import './MainPage.css';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';

function MainPage({ content }) {
    const { userDoc } = useStoreState(AppStore);
    const mobile = useMediaQuery('(max-width:650px)');

    return (
        <div
            className={
                mobile
                    ? userDoc.uid
                        ? 'main_page_mobile'
                        : 'main_page_mobile_login'
                    : userDoc.uid
                    ? 'main_page_desktop'
                    : 'main_page_desktop_login'
            }
            style={{
                maxWidth: '100vw',
                // marginLeft: !mobile ? `${drawerWidth}px` : 0,
                // marginTop: `calc(${appBarHeight}px + 24px)`,
            }}
        >
            {content}
        </div>
    );
}

export default MainPage;
