import React from 'react';
import './PassedLoader.css';
import { Box } from '@mui/material';

import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import Logo from '../../assets/images/Blue-Inline.png';

export const PassedLoader = () => {
    const { loader } = useStoreState(AppStore);
    return (
        <>
            {loader && (
                <Box className='passed_overlay'>
                    <img src={Logo} alt='Passed.ai' className='loading_logo' />
                    <Box className='passed_loader'></Box>
                </Box>
            )}
        </>
    );
};
