import React from 'react';
import { Box } from '@mui/material';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';

const Users = () => {
    const { users } = useStoreState(AppStore);
    const numberOfUsers = users?.length;

    return (
        <>
            <Box className='users_box'>
                <Box className='users_text'>{numberOfUsers} Users this month</Box>
            </Box>
        </>
    );
};

export default Users;
