import React from 'react';
import { Link } from '@mui/material';

function PassedHyperlink(props) {
    return (
        <>
            <Link
                style={{
                    color: 'black',
                    fontWeight: 'bold',
                    borderRadius: '10px',
                    lineHeight: '1.25rem',
                    cursor: 'pointer',
                }}
                variant='contained'
                onClick={props.onClick}
            >
                {props.text}
            </Link>
        </>
    );
}

export default PassedHyperlink;
