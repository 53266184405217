import { TextareaAutosize } from '@mui/material';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../stores/CurrentScan';
import { gDocWordCount, wordCount } from '../../../utils/scanFns';
import './ScanInput.css';
import { useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

const ScanInput = () => {
    const location = window.location.pathname;
    const urlParams = new URLSearchParams(window.location.search);
    const fid = urlParams.get('fid');
    const id = urlParams.get('id');

    const { scanDisabled, inputDisabled, content } = useStoreState(CurrentScan);
    const handleInput = (e) => {
        CurrentScan.update((s) => {
            s.content = e.target.value;
            s.words = e.target.value.split(' ');
            s.wordCount = wordCount(e);
        });
    };

    useEffect(() => {
        if (location === '/ext-scan') {
            CurrentScan.update((s) => {
                s.inputDisabled = true;
            });
        }
        if (fid && id) {
            // get firebase doc matching id from gDocs collection if it exists
            getDoc(doc(db, 'gDocs', id)).then((doc) => {
                if (doc.exists()) {
                    const docData = doc.data();
                    const content = JSON.parse(docData.content);
                    const copy = content.copy;
                    if (copy.length > 0) {
                        CurrentScan.update((s) => {
                            s.content = content.copy;
                            s.words = content.copy.split(' ');
                            s.wordCount = gDocWordCount(content.copy);
                        });
                    }
                }
            });
        }
        // console.log(fid, id);
    }, [fid, id, location]);
    return (
        <div className='ScanInput'>
            <TextareaAutosize
                disabled={scanDisabled || inputDisabled}
                aria-label='empty textarea'
                placeholder='Enter text here...'
                className='contentBox'
                debounce={1000}
                minRows={50}
                value={content}
                onChange={(e) => handleInput(e)}
            />
        </div>
    );
};

export default ScanInput;
