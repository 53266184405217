import './ChartLegend.css';

const ChartLegend = ({ data, context }) => {
    return (
        <div className='ChartLegend'>
            {data.map((item, index) => (
                <div className='ChartLegend__item' key={index}>
                    <div className='ChartLegend__item__label' style={{ color: item.color }}>
                        {item.label}
                    </div>
                    <div className='ChartLegend__item__value'>
                        {(context === 'plag' ? item.value : item.value * 100).toFixed(0)}%
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChartLegend;
