import React from 'react';
import { CardMedia, Unstable_Grid2 as Grid } from '@mui/material';

function OnboardingTopGrid({ src }) {
    return (
        <Grid xs={4} className='onboarding_icons'>
            <CardMedia
                component={'img'}
                src={src}
                style={{
                    maxWidth: '75%',
                }}
            />
        </Grid>
    );
}

export default OnboardingTopGrid;
