import {
    collection,
    doc,
    getDoc,
    getDocs,
    query,
    setDoc,
    where,
    updateDoc,
    or,
    addDoc,
    deleteDoc,
    increment,
} from 'firebase/firestore';
import { db } from '../firebase';
import { AppStore } from '../stores/AppStore';
import { CardStore } from '../stores/CardStore';
import { OnboardingStore } from '../stores/OnboardingStore';
import { getUsersCloud, institutionAccess } from '../../src/firebase';

export const getInstitutionUid = async (user) => {
    const userDocRef = doc(db, 'users', user.uid);
    const userDocSnap = await getDoc(userDocRef);
    let institutionId = null;

    if (userDocSnap.exists()) {
        const userDoc = userDocSnap.data();
        institutionId = userDoc.institutionId;
    }

    if (institutionId) {
        AppStore.update((s) => {
            s.institutionDoc = {
                ...s.institutionDoc,
                institutionId: institutionId,
            };
        });
    }
};

export const updateCredits = async (user) => {
    // try {
    //     //getting the institutionid from the user
    //     const userDocRef = doc(db, 'users', user.uid);
    //     const userDocSnap = await getDoc(userDocRef);
    //     const userDoc = userDocSnap.data();
    //     const institutionId = userDoc?.institutionId;
    //     const docRef = doc(db, 'institutions', institutionId);
    //     const docSnap = await getDoc(docRef);
    //     // console.log('docRef', docRef);
    //     // console.log('docSnap', docSnap);
    //     let credits;
    //     if (docSnap.exists()) {
    //         const data = docSnap.data();
    //         credits = data.credits;
    //     } else {
    //         console.error('user doc not found');
    //     }
    //     AppStore.update((s) => {
    //         s.institutionDoc = {
    //             ...s.institutionDoc,
    //             credits: credits,
    //         };
    //     });
    // } catch (error) {
    //     // Handle error here
    //     console.error(error);
    // }
};
export const getSetUserDoc = async (user) => {
    try {
        const docRef = doc(db, 'users', user.uid);
        const docSnap = await getDoc(docRef);
        let institutionId = null;

        if (docSnap.exists()) {
            institutionId = docSnap.data().institutionId;
        }

        if (institutionId) {
            const institutionResponse = await institutionAccess({
                action: 'read',
                institutionId: institutionId,
            });
            const institutionData = institutionResponse.data;

            let userDoc;
            let institutionDoc;

            if (docSnap.exists()) {
                const data = docSnap.data();
                const id = docSnap.id;

                userDoc = { ...data, id, uid: user.uid };
                institutionDoc = { ...institutionData };
            } else {
                const userData = {
                    name: user.displayName,
                    email: user.email,
                    photoUrl: user.photoURL,
                    active: false,
                    uid: user.uid,
                };

                // Assuming you need to set this data in the institutions collection
                await institutionAccess({
                    action: 'write',
                    institutionId: institutionId,
                    data: { test: true },
                });
                await setDoc(docRef, userData); // This is a direct write to Firestore. Keep it if users collection doesn't have restrictive rules
                userDoc = userData;
            }

            AppStore.update((s) => {
                s.user = user;
                s.userDoc = userDoc;
                s.institutionDoc = institutionDoc;
            });

            CardStore.update((s) => {
                s.selectedPaymentMethod = userDoc.billingDetails;
            });
        }
    } catch (error) {
        console.error(error);
    }
};

export const activeSub = async (user) => {
    try {
        //get the institution's admin's uid's
        const userRef = doc(db, 'users', user.uid);
        const userData = await getDoc(userRef);
        if (!userData.exists()) {
            // console.error('User not found.');
            return false;
        }
        const institutionId = userData.data().institutionId;

        const institutionRef = doc(db, 'institutions', institutionId);
        const institutionData = await getDoc(institutionRef);
        if (!institutionData.exists) {
            console.error('Institution not found.');
            return false;
        }
        const adminUids = institutionData.data().admin;

        // go through each of the admin UIDs
        for (let i = 0; i < adminUids.length; i++) {
            // check for an active sub for each admin
            const subRef = collection(db, 'users', adminUids[i], 'subscriptions');
            const activeSubRef = query(subRef, where('status', '==', 'active'));
            const activeSubSnap = await getDocs(activeSubRef);

            if (activeSubSnap.docs.length > 0) {
                return { hasActiveSub: true, adminUids: [] };
            }
        }

        return { hasActiveSub: false, adminUids: adminUids };
    } catch (error) {
        console.error('error', error);
        return { hasActiveSub: false, adminUids: [] };
    }
};

export const getSubscriptions = async (user) => {
    try {
        const userDocRef = doc(db, 'users', user.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (!userDocSnap.exists()) {
            return;
        }

        const institutionId = userDocSnap.data().institutionId;

        // Using Cloud Function to get institution data

        const institutionResponse = await institutionAccess({
            action: 'read',
            institutionId: institutionId,
        });
        const institutionData = institutionResponse.data;

        const adminUids = institutionData.admin || [];
        let foundSubscription = false;

        for (const adminUid of adminUids) {
            // Assuming you have permissions set to directly query user subscriptions
            const subRef = collection(db, 'users', adminUid, 'subscriptions');
            const activeSubRef = query(
                subRef,
                or(where('status', '==', 'active'), where('status', '==', 'trialing'))
            );
            const activeSubSnap = await getDocs(activeSubRef);

            if (activeSubSnap.docs.length > 0) {
                const subsArray = [];
                activeSubSnap.docs.forEach((doc) => {
                    const subData = doc.data();
                    const id = doc.id;
                    subsArray.push({ ...subData, id });
                });

                await updateDoc(userDocRef, {
                    active: true,
                });

                AppStore.update((s) => {
                    s.userDoc = {
                        ...s.userDoc,
                        subscriptions: subsArray,
                        active: true,
                    };
                    s.institutionDoc = {
                        ...s.institutionDoc,
                        subQuantity: subsArray[0].quantity,
                    };
                });

                OnboardingStore.update((s) => {
                    s.step = 3;
                });

                foundSubscription = true;
                break;
            }
        }

        if (!foundSubscription) {
            AppStore.update((s) => {
                s.userDoc = {
                    ...s.userDoc,
                    subscriptions: [],
                    active: false,
                };
            });
            await updateDoc(userDocRef, {
                active: false,
            });
        }
    } catch (error) {
        console.error('getSubscriptions error', error);
    }
};

export const getUsers = async () => {
    getUsersCloud().then((result) => {
        const users = result.data.users;

        AppStore.update((s) => {
            s.users = users;
        });
    });
};

export const getPlans = async () => {
    try {
        const plansQuery = query(collection(db, 'plans'), where('active', '==', true));
        const querySnapshot = await getDocs(plansQuery);

        const plansArray = [];

        for (const doc of querySnapshot.docs) {
            const priceSnap = await getDocs(collection(doc.ref, 'prices'));
            const prices = [];

            for (const priceDoc of priceSnap.docs) {
                const pData = priceDoc.data();
                const pId = priceDoc.id;
                if (pData.active) {
                    prices.push({ ...pData, id: pId });
                }
            }

            plansArray.push({ ...doc.data(), id: doc.id, prices });
        }

        AppStore.update((s) => {
            s.plans = plansArray;
        });
    } catch (error) {
        // Handle error here
        console.error(error);
    }
};

export const getPlan = async (id) => {
    //get doc matching id
    const planRef = doc(db, 'plans', id);
    const planSnap = await getDoc(planRef);
    const planData = planSnap.data();
    const planId = planSnap.id;
    const plan = { ...planData, id: planId };

    //get prices
    const priceRef = collection(db, 'plans', id, 'prices');
    const priceSnap = await getDocs(priceRef);
    const prices = [];
    priceSnap.docs.forEach((doc) => {
        const data = doc.data();
        const id = doc.id;
        prices.push({ ...data, id });
    });
    plan.prices = prices;

    return plan;
};

export const addDocToSubCollection = async (user, subCollectionName, data, store) => {
    const userRef = doc(db, 'institutions', user.institutionId);
    const subCollectionRef = collection(userRef, subCollectionName);

    const docRef = await addDoc(subCollectionRef, data);

    store.update((s) => {
        const updatedData = [...s[subCollectionName], { ...data, id: docRef.id }];
        return { ...s, [subCollectionName]: updatedData };
    });

    return docRef.id;
};

export const editDocInSubCollection = async (collection, user, docId, newData, store) => {
    const id = collection === 'institutions' ? user.institutionId : docId;
    const userRef = doc(db, collection, id);

    await updateDoc(userRef, newData)
        .then((result) => {
            // console.log('Successfully updated document: ', result);
        })
        .catch((error) => {
            console.error('Update Error:', error);
        });

    if (store) {
        store.update((s) => {
            const updatedUsers = s.users.map((item) =>
                item.id === docId ? { ...item, ...newData } : item
            );
            return { ...s, users: updatedUsers };
        });
    }
};

export const deleteDocFromSubCollection = async (userType, docId, email, store) => {
    let docRef;

    if (docId) {
        docRef = doc(db, 'users', docId);
    } else if (email) {
        const querySnapshot = await getDocs(
            query(collection(db, 'users'), where('email', '==', email))
        );
        if (!querySnapshot.empty) {
            docRef = doc(db, 'users', querySnapshot.docs[0].id);
        } else {
            console.error('No user found with the provided email');
            return;
        }
    } else {
        console.error('Neither docId nor email provided');
        return;
    }

    await deleteDoc(docRef);

    if (store) {
        store.update((s) => {
            const updatedData = s.users.filter((item) => item.id !== docId);
            return { ...s, users: updatedData };
        });
    }
};

export const updateInstitutionSubUserCredits = async (institutionId) => {
    const institutionUsersRef = collection(db, 'institutions', institutionId, 'users');

    const institutionUsersSnapshot = await getDocs(institutionUsersRef);

    // Loop through each user
    institutionUsersSnapshot.forEach(async (document) => {
        const institutionUserData = document.data();
        const mainUserRef = doc(db, 'users', institutionUserData.uid);
        const mainUserDoc = await getDoc(mainUserRef);

        if (mainUserDoc.exists) {
            const mainUserData = mainUserDoc.data();

            // Check if credits are different and update if necessary

            await updateDoc(doc(institutionUsersRef, document.id), {
                credits: mainUserData.credits,
            });
            AppStore.update((s) => {
                const userIndex = s.users.findIndex((user) => user.uid === institutionUserData.uid);

                // If found, update only this user's credits
                if (userIndex !== -1) {
                    s.users[userIndex].credits = mainUserData.credits;
                }
            });
        }
    });
};

export const updateMainUserCreditsByEmail = async (userEmail, newCredits) => {
    const userQuery = query(collection(db, 'users'), where('email', '==', userEmail));
    // console.log('newCredits', newCredits);
    const querySnapshot = await getDocs(userQuery);
    // console.log('querySnapshot', querySnapshot);

    querySnapshot.forEach((doc) => {
        //backend
        updateDoc(doc.ref, {
            credits: newCredits,
        });
        //frontend
        AppStore.update((s) => {
            const userIndex = s.users.findIndex((user) => user.email === userEmail);

            // If found, update only this user's credits
            if (userIndex !== -1) {
                s.users[userIndex].credits = newCredits;
            }
        });
    });
};

export const changeCredits = async (institutionId, amount) => {
    const institutionRef = doc(db, 'institutions', institutionId);

    await updateDoc(institutionRef, {
        credits: increment(amount),
    });

    AppStore.update((s) => {
        const updatedCredits = s.institutionDoc.credits + amount;
        s.institutionDoc = {
            ...s.institutionDoc,
            credits: updatedCredits,
        };
    });
};
