import React from 'react';
import Header from '../../components/MUI/Header';
import ManageUsersTable from '../../components/ManageUsersTable/ManageUsersTable';

function ManageUsers() {
    return (
        <>
            <Header text='Manage Users' />
            <ManageUsersTable />
        </>
    );
}

export default ManageUsers;
