import { Box } from '@mui/material';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../stores/CurrentScan';
import ChartLabel from '../DoughnutChart/ChartLabel/ChartLabel';
import ChartLegend from '../DoughnutChart/ChartLegend/ChartLegend';
import DoughnutChart from '../DoughnutChart/DoughnutChart';
import './AIPanel.css';

const AIPanel = ({ value, index, ...other }) => {
    const { results } = useStoreState(CurrentScan);

    const aiData = {
        chartLabel: <ChartLabel label={'AI Confidence Score'} />,
        chartData: [
            {
                label: 'AI',
                value: results?.result?.scan_results?.aiResults?.fake,
                color: '#D32F2F',
            },
            {
                label: 'Not AI',
                value: results?.result?.scan_results?.aiResults?.real,
                color: 'rgb(79 180 119 / 1)',
            },
        ],
    };
    return (
        <div
            className='AIPanel'
            role='tabpanel'
            hidden={value !== index}
            id={`ai-results-tabpanel-${index}`}
            aria-labelledby={`ai-results-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <DoughnutChart
                        size={300}
                        chartlabel={aiData.chartLabel}
                        data={aiData.chartData}
                    />
                    <ChartLegend data={aiData.chartData} />
                    <p className='AIPanel__text'>
                        The "AI Confidence Score" reflects our AI's confidence in predicting that
                        the content scanned was produced by an AI tool using GPT-2, GPT-J, GPT-NEO
                        or GPT-3. A score of 90% Not AI and 10% AI should be thought of as "We are
                        90% confident that this content was created by a human" and NOT that 90% of
                        the article is Human and 10% AI.
                    </p>
                </Box>
            )}
        </div>
    );
};

export default AIPanel;
