import { ExpandMore } from '@mui/icons-material';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Chip,
    Divider,
    Typography,
} from '@mui/material';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../../stores/CurrentScan';
import './WebsiteAccordions.css';

const WebsiteAccordions = () => {
    const { results } = useStoreState(CurrentScan);
    return (
        <div className='PlagPanel__table'>
            <div className='PlagPanel__table-title'>Plagiarized Text</div>
            <div>
                {results?.result?.scan_results?.plagResults?.websiteResults.map((phrase, index) => {
                    return phrase?.results.map((res, i) => {
                        const url = phrase?.website;
                        let domain = new URL(url);
                        domain = domain.hostname.replace('www.', '');

                        return (
                            <Accordion key={index + i}>
                                <AccordionSummary
                                    className='PlagPanel__table-row'
                                    expandIcon={<ExpandMore />}
                                    aria-controls={`panel${index + i}-content`}
                                    id={`panel${i}-header`}
                                >
                                    <Typography>{domain}</Typography>
                                    <Chip
                                        label={res?.score + '%'}
                                        color='danger'
                                        variant='outlined'
                                    />
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Typography>"{res?.phrase}"</Typography>
                                    <Divider sx={{ mt: 2, mb: 2 }} />
                                    <a
                                        className='siteLink'
                                        href={phrase?.website}
                                        target='_blank'
                                        rel='noreferrer'
                                    >
                                        {phrase?.website}
                                    </a>
                                </AccordionDetails>
                            </Accordion>
                        );
                    });
                })}
            </div>
        </div>
    );
};

export default WebsiteAccordions;
