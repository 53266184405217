import './HelpCard.css';
import { Typography } from '@mui/material';

const HelpCard = function (props) {
    return (
        <div id={props.id}>
            <div className='card-container'>
                <div className='card-top'>
                    <h2>
                        <Typography sx={{ mt: 2, mb: 2 }} variant='h4' component='div'>
                            {props.header}
                        </Typography>
                    </h2>
                </div>
                <div className='card-bottom'>{props.body}</div>
            </div>
        </div>
    );
};
export default HelpCard;
