import { React, useState } from 'react';
import { Box } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility } from '@mui/icons-material';
import './Invoices.css';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { getInvoices } from '../../../src/firebase';

// DO NOT DELETE COMMENTS //
// options disabled for now - code is working

const Invoices = () => {
    const [loading, setLoading] = useState(false);
    const { userDoc } = useStoreState(AppStore);
    const stripeId = userDoc.stripeId;

    // const allInvoices = () => {
    //     getInvoices({ customerId: stripeId, invoiceType: 'all' }).then((result) => {
    //         const invoiceUrls = result.data;
    //         invoiceUrls.forEach((url) => {
    //             window.open(url, '_blank');
    //         });
    //     });
    // };

    const previousInvoice = () => {
        setLoading(true);
        getInvoices({ customerId: stripeId, invoiceType: 'previous' })
            .then((result) => {
                const invoiceUrl = result.data;
                window.open(invoiceUrl, '_blank');
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                alert('There was an issue getting your subscription');
            });
    };

    // const currentInvoice = () => {
    //     getInvoices({ customerId: stripeId, invoiceType: 'current' }).then((result) => {
    //         const invoiceUrl = result.data;
    //         window.open(invoiceUrl, '_blank');
    //     });
    // };
    return (
        <Box className='invoices_box'>
            {/* <Button onClick={currentInvoice} className='invoices_button'>
                view current invoice
            </Button> */}
            <LoadingButton
                disabled={loading}
                loading={loading}
                onClick={previousInvoice}
                className='invoices_button'
                loadingPosition='start'
                startIcon={<Visibility />}
            >
                view your invoice
            </LoadingButton>
            {/* <Button onClick={allInvoices} className='invoices_button'>
                view all Invoices
            </Button> */}
        </Box>
    );
};

export default Invoices;
