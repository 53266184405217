import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const Toggle = ({ handleChange, value, labels }) => {
    return (
        <div className='toggle'>
            <ToggleButtonGroup
                color='primary'
                value={value}
                exclusive
                onChange={handleChange}
                aria-label='Interval'
            >
                {labels?.map((label, i) => (
                    <ToggleButton
                        key={i}
                        color={value === label ? 'primary' : null}
                        value={label}
                        className={value === label ? `${label} active` : `${label}`}
                    >
                        {label}
                    </ToggleButton>
                ))}
            </ToggleButtonGroup>
        </div>
    );
};

export default Toggle;
