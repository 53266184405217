import AccountCard from '../../components/AccountCard/AccountCard';
import Header from '../../components/MUI/Header';
import './Account.css';
import '../../components/MUI/MuiStyles.css';
import { Box } from '@mui/material';

const Account = () => {
    return (
        <Box className='mui-box'>
            <Header text='Account Details' />
            <div className='user-info'>
                <AccountCard />
            </div>
        </Box>
    );
};

export default Account;
