import { useEffect, useState } from 'react';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import PlanCard from '../../components/PlanCard/PlanCard';
import Toggle from '../../components/Toggle/Toggle';
import './Subscriptions.css';
import { getSubscriptions } from '../../utils/getData';
import { getAuth } from 'firebase/auth';
import { Button, Box } from '@mui/material';
import Header from '../../components/MUI/Header';

const Subscriptions = () => {
    const { subscriptions, plans, userDoc } = useStoreState(AppStore);
    const [timing, setTiming] = useState('yearly');
    //get query params
    const urlParams = new URLSearchParams(window.location.search);
    const success = urlParams.get('success');
    // const cancelled = urlParams.get('cancelled');
    const user = getAuth().currentUser;
    // console.log('success', success);
    const handleChange = (event, newAlignment) => {
        setTiming(newAlignment);
    };

    useEffect(() => {
        if (success) {
            getSubscriptions(user);
        }
    }, [success, user]);

    return (
        <>
            {!userDoc.active && (
                <>
                    <h1>Subscribe to a plan</h1>
                    <Toggle
                        handleChange={handleChange}
                        value={timing}
                        labels={['yearly', 'monthly']}
                    />

                    <Box flexDirection={{ xs: 'column', md: 'row' }} className='plans'>
                        {plans?.map((plan, i) => (
                            <PlanCard
                                key={i}
                                plan={plan}
                                price={
                                    timing === 'yearly'
                                        ? plan?.prices?.find((price) => price?.interval === 'year')
                                        : plan?.prices?.find((price) => price?.interval === 'month')
                                }
                                subscribed={subscriptions?.find(
                                    (sub) => sub?.product?.id === plan?.id
                                )}
                            />
                        ))}
                    </Box>
                </>
            )}
            {userDoc.active && (
                <div className='manage'>
                    <Header text='Manage your subscription' />
                    <div className='manage-container'>
                        <p>
                            You can manage your subscription by clicking the button below. You can
                            cancel your subscription at any time.
                        </p>
                        <div style={{ height: '1rem' }} />
                        <Button
                            variant='contained'
                            color='secondary'
                            size='large'
                            className='passed-btn centered'
                            onClick={() =>
                                window.open(
                                    //set url based on prod or dev
                                    process.env.NODE_ENV === 'production'
                                        ? `https://billing.stripe.com/p/login/cN26oKgHQ0jPaM88ww?prefilled_email=${user?.email}`
                                        : `https://billing.stripe.com/p/login/test_cN27uafW05Yr51K3cc?prefilled_email=${user?.email}`,
                                    '_blank' // <- This is what makes it open in a new window.
                                )
                            }
                        >
                            Manage subscription
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};

export default Subscriptions;
