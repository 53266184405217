import {
    Alert,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Snackbar,
    TextField,
    Unstable_Grid2 as Grid,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useCallback, useEffect, useState } from 'react';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import Subscriptions from '../../Pages/Subscriptions/Subscriptions';
import { AdminModal } from './../AdminModal/AdminModal';
import { Save, ThumbUp, ThumbDown } from '@mui/icons-material';
import './AccountCard.css';

const AccountCard = () => {
    const { userDoc, institutionDoc } = useStoreState(AppStore);
    const { institution, level } = institutionDoc;
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [addEmail, setAddEmail] = useState(false);
    const [adminEmails, setAdminEmails] = useState([]);

    useEffect(() => {
        if (success || error) {
            setTimeout(() => {
                setSuccess(false);
                setError(false);
            }, 5000);
        }
    }, [success, error]);

    useEffect(() => {
        const getAdminEmails = async () => {
            let emailList = [];
            for (const adminUid of institutionDoc.admin) {
                const userRef = doc(db, 'users', adminUid);
                const userDoc = await getDoc(userRef);
                if (userDoc.exists()) {
                    emailList.push(userDoc.data().email);
                }
            }
            setAdminEmails(emailList);
        };

        getAdminEmails();
    }, [institutionDoc.admin]);

    const deleteAdmin = async (index) => {
        try {
            if (institutionDoc.admin.length <= 1) {
                setError(true);
                setErrorMsg('At least one admin must be present.');
                return;
            }

            const uidToRemove = institutionDoc.admin[index];

            const newAdminUids = [...institutionDoc.admin];
            newAdminUids.splice(index, 1);
            updateUserDoc('admin', newAdminUids);
            const userRef = doc(db, 'users', institutionDoc.admin[index]);
            const institutionRef = doc(db, 'institutions', institutionDoc.institutionId);

            //adding the admin flag to the user in firebase
            await setDoc(userRef, { admin: false }, { merge: true });
            await setDoc(institutionRef, { admin: newAdminUids }, { merge: true });

            AppStore.update((s) => {
                const userToUpdate = s.users.find((u) => u.uid === uidToRemove);
                if (userToUpdate) {
                    userToUpdate.admin = false;
                }
            });
        } catch (error) {
            setError(true);
            setErrorMsg('An error occured. Please try again later');
        }
    };

    const updateUserDoc = (key, value) => {
        if (key === 'admin' && (value === undefined || value === '')) {
            setError(true);
            setErrorMsg('Please make sure there is at least one admin');
        }
        AppStore.update((s) => {
            s.institutionDoc = {
                ...s.institutionDoc,
                [key]: value,
            };
        });
    };

    const updateDoc = async () => {
        setSaving(true);
        const institutionRef = doc(db, 'institutions', institutionDoc.institutionId);

        setDoc(institutionRef, institutionDoc, { merge: true })
            .then(() => {
                console.log('document uploaded successfully');
                setSaving(false);
                setSuccess(true);
            })
            .catch((error) => {
                setSaving(false);
                setError(true);
                console.log('error', error);
            });
    };

    const handleSubmit = useCallback((event) => {
        event.preventDefault();
    }, []);

    const levelOptions = [
        {
            value: 'high school',
            label: 'High School',
        },
        {
            value: 'university',
            label: 'University',
        },
    ];

    return (
        <>
            {addEmail && (
                <AdminModal
                    open={addEmail}
                    setAddEmail={setAddEmail}
                    adminEmails={adminEmails}
                    setAdminEmails={setAdminEmails}
                />
            )}
            <Snackbar
                sx={{ width: '100%' }}
                open={success || error}
                autoHideDuration={5000}
                onClose={() => {
                    setError(false);
                    setSuccess(false);
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                {success && !error ? (
                    <Alert severity='success' sx={{ width: '400px', margin: '0 auto' }}>
                        Your account has been updated
                    </Alert>
                ) : !success && error ? (
                    <Alert severity='error' sx={{ width: '400px', margin: '0 auto' }}>
                        {errorMsg
                            ? errorMsg
                            : `We've encountered an error. Please try again later.`}
                    </Alert>
                ) : null}
            </Snackbar>
            <div>
                <form autoComplete='off' onSubmit={handleSubmit}>
                    <Card>
                        <CardHeader title='User Information' />
                        <CardContent>
                            <Box>
                                <Grid container spacing={3}>
                                    <Grid xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label='Institution'
                                            name='institution'
                                            onChange={(e) => {
                                                updateUserDoc('institution', e.target.value);
                                            }}
                                            defaultValue={institution}
                                        />
                                    </Grid>

                                    <Grid xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            label='Institution Level'
                                            name='institution level'
                                            onChange={(e) => {
                                                updateUserDoc('level', e.target.value);
                                            }}
                                            select
                                            SelectProps={{ native: true }}
                                            defaultValue={level}
                                        >
                                            {levelOptions.map((option) => (
                                                <option key={option.value} value={option.value}>
                                                    {option.label}
                                                </option>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <Box className='admin_container'>
                                            <Box className='admin_label'>
                                                Administrators
                                                <Button
                                                    className='add_button'
                                                    onClick={() => setAddEmail(true)}
                                                >
                                                    Add
                                                </Button>
                                            </Box>
                                            {adminEmails.map((adminEmail, index) => (
                                                <div className='admin_box' key={index}>
                                                    {adminEmail}
                                                    <Button
                                                        className='delete_button'
                                                        onClick={() => deleteAdmin(index)}
                                                    >
                                                        Delete
                                                    </Button>
                                                </div>
                                            ))}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </CardContent>
                        <Divider variant='middle' orientation='vertical' />

                        <CardActions sx={{ justifyContent: 'flex-end' }}>
                            <LoadingButton
                                className='passed-btn'
                                variant='contained'
                                disabled={saving}
                                style={{ margin: '1.5rem 0' }}
                                onClick={updateDoc}
                                loading={saving}
                                loadingPosition='start'
                                color={success ? 'success' : error ? 'error' : 'secondary'}
                                startIcon={success ? <ThumbUp /> : error ? <ThumbDown /> : <Save />}
                            >
                                Update Info
                            </LoadingButton>
                        </CardActions>
                    </Card>
                </form>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    {userDoc.active === true ? <Subscriptions /> : ''}
                </div>
            </div>
        </>
    );
};

export default AccountCard;
