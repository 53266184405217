import IconMask6 from '../../assets/images/IconMask6.png';
import React from 'react';

let iconId = 0;

export default function PassedIcon({ iconPath }) {
    const clipPathId = `iconShape${iconId++}`;

    return (
        <svg width='24' height='24' viewBox='0 0 24 24'>
            <defs>
                <clipPath id={clipPathId}>
                    <path d={iconPath} />
                </clipPath>
            </defs>
            <image href={IconMask6} width='24' height='24' clipPath={`url(#${clipPathId})`} />
        </svg>
    );
}
