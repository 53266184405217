import { Alert } from '@mui/material';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../../stores/CurrentScan';
import './ScanInstructions.css';

const ScanInstructions = () => {
    const { wordCount } = useStoreState(CurrentScan);
    return (
        <div className='scan-instructions'>
            {wordCount < 50 && (
                <Alert severity='info'>We require a minimum of 50 words to scan.</Alert>
            )}
            <h2>How Scanning Works</h2>
            <p>Enter your text content in the box on the left.</p>
            <p>You have 3 options when scanning:</p>
            <ul>
                <li>Scan for AI content</li>
                <li>Scan for plagiarism</li>
                <li>Scan for both</li>
            </ul>
            <p>
                Scans require credits, which you receive each month as part of your plan. Your
                account will be charged 1 credit/100 words if you select AI detection
                <strong> OR</strong> plagiarism. Sanning for <strong>BOTH</strong> will cost 2
                credits/100 words. Your current credit balance is shown in the top right corner.
            </p>
            <p>
                When you are ready, click the Scan Now button. Longer content and plagiarism checks
                will add to the time it takes your scan to complete. Please be patient as the web is
                a big place.
            </p>
            <p> When the scan is complete, your results will appear here.</p>
        </div>
    );
};

export default ScanInstructions;
