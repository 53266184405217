import { Box } from '@mui/material';
import { useStoreState } from 'pullstate';
import { Fragment } from 'react';
import { CurrentScan } from '../../../stores/CurrentScan';
import ChartLabel from '../DoughnutChart/ChartLabel/ChartLabel';
import ChartLegend from '../DoughnutChart/ChartLegend/ChartLegend';
import DoughnutChart from '../DoughnutChart/DoughnutChart';
import './PlagPanel.css';
import WebsiteAccordions from './WebsiteAccordions/WebsiteAccordions';

const PlagPanel = ({ value, index, ...other }) => {
    const { results } = useStoreState(CurrentScan);
    const plagData = {
        chartLabel: <ChartLabel label={'Plagiarism Score'} />,
        chartData: [
            {
                label: 'Plagiarized',
                value: results?.result?.scan_results?.plagResults?.documentScore,
                color: '#D32F2F',
            },
            {
                label: 'Not Plagiarized',
                value: 100 - results?.result?.scan_results?.plagResults?.documentScore,
                color: 'rgb(79 180 119 / 1)',
            },
        ],
    };
    return (
        <div
            className='PlagPanel'
            role='tabpanel'
            hidden={value !== index}
            id={`plag-results-tabpanel-${index}`}
            aria-labelledby={`plag-results-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {results && (
                        <Fragment>
                            <DoughnutChart
                                size={300}
                                chartlabel={plagData.chartLabel}
                                data={plagData.chartData}
                            />
                            <ChartLegend context='plag' data={plagData.chartData} />
                            <p className='PlagPanel__text'>
                                The Plagiarized value is the percent of your content that contains
                                plagiarism according to our scan. Refer to the table below to view
                                the specific websites we found the plagiarism on.{' '}
                                <strong>Please note:</strong> We do not scan small amounts of text
                                or common phrases that would be common to many websites.
                            </p>
                            <div style={{ height: '1.5rem' }} />
                            <WebsiteAccordions />
                        </Fragment>
                    )}
                </Box>
            )}
        </div>
    );
};

export default PlagPanel;
