import { Card, CardActions, CardContent, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { db } from '../../firebase';
import { collection, doc, setDoc, addDoc, onSnapshot } from 'firebase/firestore'; // import the required methods
import './PlanCard.css';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { useState } from 'react';
import PaymentIcon from '@mui/icons-material/Payment';

const PlanCard = ({ plan, price, onClick, subscribed }) => {
    const { user, subscriptions, clientReferenceId } = useStoreState(AppStore);
    const [loading, setLoading] = useState(false);

    // function to flag the user as having started payment
    const setPaymentStarted = async () => {
        try {
            const cartDocRef = doc(db, 'users', user.uid);
            const userData = { paymentStarted: true };
            await setDoc(cartDocRef, userData, { merge: true });
        } catch (error) {
            console.error(error);
        }
    };

    const startSubscription = () => {
        setLoading(true);
        const subscribe = async () => {
            const docRef = await addDoc(collection(db, 'users', user.uid, 'checkout_sessions'), {
                price: price?.id,
                trial_from_plan: subscriptions?.length ? false : true,
                success_url: window.location.href + '/?success=true',
                cancel_url: window.location.href + '/?cancelled=true',
                allow_promotion_codes: true,
                collect_shipping_address: false,
                client_reference_id: clientReferenceId || '',
            });
            // Wait for the CheckoutSession to get attached by the extension
            onSnapshot(docRef, (snap) => {
                // console.log('snap', snap.data());
                const { error, url } = snap.data();
                console.log('error', error);
                // console.log('url', url);
                if (error) {
                    // Show an error to your customer and
                    // inspect your Cloud Function logs in the Firebase console.
                    console.log(`An error occurred: ${error.message}`);
                }
                if (url) {
                    //first set the paymentStarted flag to true in Firestore
                    setPaymentStarted();
                    // We have a Stripe Checkout URL, let's redirect.
                    window.location.assign(url);
                    setLoading(false);
                }
            });
        };

        subscribe();
    };
    const manageSubscription = () => {
        setLoading(true);
        const manage = () => {
            window.open(
                //set url based on prod or dev
                process.env.NODE_ENV === 'production'
                    ? 'https://billing.stripe.com/p/login/cN26oKgHQ0jPaM88ww'
                    : 'https://billing.stripe.com/p/login/test_cN27uafW05Yr51K3cc',
                '_blank' // <- This is what makes it open in a new window.
            );
        };
        manage();
        setLoading(false);
    };
    // console.log('plan', plan);
    return (
        <Card className='plan-card' onClick={onClick}>
            <CardContent>
                <Typography className='plan-name'>{plan.name}</Typography>
                {plan?.description?.split('.').map((desc, i) => (
                    <Typography key={i} className='plan-desc' component='p'>
                        {desc}
                    </Typography>
                ))}
                <Typography className='plan-price'>
                    <sup className='currency'>
                        {price?.currency.slice(0, price?.currency.length - 1)}
                    </sup>
                    ${price?.unit_amount / 100}
                </Typography>
            </CardContent>
            <CardActions>
                <LoadingButton
                    loading={loading}
                    onClick={() =>
                        loading ? null : subscribed ? manageSubscription() : startSubscription()
                    }
                    variant='contained'
                    color='secondary'
                    className='passed-btn'
                    size='large'
                    loadingPosition='start'
                    startIcon={<PaymentIcon />}
                >
                    {loading ? 'Loading...' : subscribed ? 'Manage Subscription' : 'Subscribe'}
                </LoadingButton>
            </CardActions>
        </Card>
    );
};

export default PlanCard;
