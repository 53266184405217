import React from 'react';
import { Button, Box } from '@mui/material';
import { OnboardingStore } from '../../stores/OnboardingStore';
import { useStoreState } from 'pullstate';
import { AppStore } from '../../stores/AppStore';
import { doc, setDoc, addDoc, collection, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { PassedLoader } from '../MUI/PassedLoader';

function Footer({ disabled, nextStep, previousStep, backButton }) {
    const { userDoc } = useStoreState(OnboardingStore);
    // console.log('userDoc', userDoc);

    const confirm = async () => {
        try {
            AppStore.update((s) => {
                s.loader = true;
            });
            const userRef = doc(db, 'users', userDoc.uid);
            // console.log('userDoc.uid', userDoc.uid);
            const userSnap = await getDoc(userRef);
            let institutionId;

            if (userSnap.exists()) {
                // console.log('userSnap', userSnap);
                const fbUser = userSnap.data();
                institutionId = fbUser.institutionId;
            }

            if (institutionId) {
                // Check if institution exists
                const institutionRef = doc(db, 'institutions', institutionId);
                const instSnap = await getDoc(institutionRef);
                if (!instSnap.exists()) {
                    // If it doesn't exist, create a new one
                    const newInstitutionRef = await addDoc(collection(db, 'institutions'), {});
                    institutionId = newInstitutionRef.id;
                }
            } else {
                // If institutionId doesn't exist in the latest userDoc, create a new institution
                const newInstitutionRef = await addDoc(collection(db, 'institutions'), {});
                institutionId = newInstitutionRef.id;
            }

            // Update user and institution data
            const institutionData = {
                level: userDoc.level,
                institution: userDoc.institution,
                institutionId: institutionId,
                admin: [userDoc.uid],
            };

            const userData = {
                ...userDoc,
                ready: true,
                admin: true,
                institutionId: institutionId,
            };

            // Update the app state
            AppStore.update((s) => {
                s.userDoc = { userData };
                s.institutionDoc = institutionData;
            });

            await setDoc(doc(db, 'institutions', institutionId), institutionData, { merge: true });
            await setDoc(userRef, userData, { merge: true });
        } catch (error) {
            console.error(error);
            AppStore.update((s) => {
                s.loader = false;
            });
        }
        window.location.reload();
    };

    return (
        <>
            <PassedLoader />
            <Box className='onboarding_step_footer'>
                <Button
                    disabled={disabled}
                    variant='contained'
                    className='onboarding_button'
                    onClick={() => {
                        if (!nextStep) {
                            confirm();
                        } else {
                            OnboardingStore.update((s) => {
                                s.step = nextStep;
                            });
                        }
                    }}
                >
                    {nextStep ? 'Next' : 'Confirm'}
                </Button>
                {backButton && (
                    <Button
                        variant='text'
                        className='onboarding_button prev'
                        onClick={() => {
                            OnboardingStore.update((s) => {
                                s.step = previousStep;
                            });
                        }}
                    >
                        Go Back
                    </Button>
                )}
            </Box>
        </>
    );
}

export default Footer;
