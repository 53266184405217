import { React } from 'react';
import { useStoreState } from 'pullstate';
import { AppStore } from '../stores/AppStore';
import { Box } from '@mui/material';
import Header from '../components/MUI/Header';
import Subheader from '../components/MUI/Subheader';
import '../components/MUI/MuiStyles.css';
import ChillRobot from '../assets/images/ChillRobot.gif';
import useMediaQuery from '@mui/material/useMediaQuery';
import AdminCard from '../components/AdminCard/AdminCard';

const Home = () => {
    const { userDoc, institutionDoc } = useStoreState(AppStore);
    const desktop = useMediaQuery('(max-width:1200px)');

    return (
        <>
            <Box className='mui-box' style={{ position: 'relative', zIndex: 2 }}>
                <Header text={`${institutionDoc.institution}'s Dashboard`} />
                <Subheader text={`Welcome back${userDoc.firstName && ', ' + userDoc.firstName}!`} />
                <AdminCard />
            </Box>
            {!desktop && (
                <Box style={{ left: 250, top: 120, position: 'fixed', zIndex: 1 }}>
                    <img className='robot-app' src={ChillRobot} alt='Robot'></img>
                </Box>
            )}
        </>
    );
};

export default Home;
