import React from 'react';
import { Collapse, Alert } from '@mui/material';

function PassedAlert({ fire, severity, message }) {
    return (
        <Collapse in={fire} sx={{ width: '22.5rem' }}>
            <Alert severity={severity} sx={{ mb: '1rem' }} message={message}>
                {message}
            </Alert>
        </Collapse>
    );
}

export default PassedAlert;
