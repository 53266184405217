import { Button } from '@mui/material';
import { OnboardingStore } from '../../../stores/OnboardingStore';

const Step5 = () => {
    return (
        <div className='onboarding_step step_one active'>
            <div className='onboarding_step step_two'>
                <p className='step_number'>STEP 4 OF 5</p>
                <h1>Institutional?</h1>
                <p className='onboarding_text'>
                    Are you interested in using Passed.ai for your institution?
                </p>
                <div className='onboarding_step_content'></div>
                <div className='onboarding_step_footer'>
                    <Button
                        variant='text'
                        className='onboarding_button prev'
                        onClick={() => {
                            OnboardingStore.update((s) => {
                                s.step = 3;
                            });
                        }}
                    >
                        Go Back
                    </Button>
                    <Button
                        variant='contained'
                        className='onboarding_button'
                        onClick={() => {
                            OnboardingStore.update((s) => {
                                s.step = 5;
                            });
                        }}
                    >
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Step5;
