import ScanInput from './ScanInput/ScanInput';
import Grid from '@mui/material/Unstable_Grid2';
import SideBarResults from './SideBarResults/SideBarResults';
import ScanOptions from './ScanOptions/ScanOptions';
import ScanButton from './ScanButton/ScanButton';
import ScanInstructions from './ScanInstructions/ScanInstructions';
import Scanning from './Scanning/Scanning';
import Results from '../Results/Results';
import { useStoreState } from 'pullstate';
import { CurrentScan } from '../../stores/CurrentScan';
import { useEffect } from 'react';
import { calcCredits } from '../../utils/scanFns';
import './Scanner.css';
import { Alert } from '@mui/material';

const Scanner = () => {
    const { wordCount, aiCheck, plagiarismCheck, results, scanDisabled, error } =
        useStoreState(CurrentScan);

    useEffect(() => {
        // console.log(calcCredits({ wordCount, aiCheck, plagiarismCheck }));
        CurrentScan.update((s) => {
            s.credits = calcCredits({ wordCount, aiCheck, plagiarismCheck });
        });
    }, [wordCount, aiCheck, plagiarismCheck]);

    return (
        <>
            <Grid container spacing={2} minWidth={1}>
                <Grid xs={12} md={8} lg={8}>
                    <ScanOptions />
                    {!results && <ScanInput />}
                    {results && <Results />}
                </Grid>
                <Grid xs={12} md={4} lg={4}>
                    <ScanButton />
                    {error && (
                        <Alert severity='error' sx={{ m: 2 }}>
                            There was an error scanning this text. If this persists, please contact
                            us using the help chat in the bottom right of the screen.
                        </Alert>
                    )}
                    {results && <SideBarResults />}
                    {!scanDisabled && !results && <ScanInstructions />}
                    {!results && scanDisabled && <Scanning />}
                </Grid>
            </Grid>
        </>
    );
};

export default Scanner;
