import { LoadingButton } from '@mui/lab';
import React from 'react';
import PaymentIcon from '@mui/icons-material/Payment';

function PassedLoadingButton(props) {
    return (
        <LoadingButton
            style={{
                backgroundColor: '#FCBA04',
                color: 'black',
                margin: '0.5rem',
                padding: '1rem',
                borderRadius: '10px',
                lineHeight: '1.25rem',
            }}
            variant='contained'
            startIcon={<PaymentIcon />}
            loadingPosition='start'
            loading={props.loading}
            onClick={props.onClick}
        >
            {props.text}
        </LoadingButton>
    );
}

export default PassedLoadingButton;
