import { Badge, Box, Tab, Tabs } from '@mui/material';
import { useStoreState } from 'pullstate';
import { useState } from 'react';
import { CurrentScan } from '../../../stores/CurrentScan';
import AIPanel from '../AIPanel/AIPanel';
import PlagPanel from '../PlagPanel/PlagPanel';
import './ResultsTabs.css';

function a11yProps(index) {
    return {
        id: `results-tab-${index}`,
        'aria-controls': `results-tabpanel-${index}`,
    };
}
const ResultsTabs = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const { results } = useStoreState(CurrentScan);
    const plagSources = results?.result?.scan_results?.plagResults?.sources;
    const handleChange = (event, newValue) => {
        setCurrentTab(newValue);
    };
    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={currentTab} onChange={handleChange} aria-label='results tabs'>
                    <Tab label='AI Score' {...a11yProps(0)} />
                    <Tab
                        disabled={results?.result?.scan_results?.scan?.scan_type === 1}
                        label={
                            <Badge badgeContent={plagSources ? plagSources : null} color='danger'>
                                {'Plagiarism Score'}
                            </Badge>
                        }
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Box>
            <AIPanel value={currentTab} index={0} />
            <PlagPanel value={currentTab} index={1} />
        </>
    );
};
export default ResultsTabs;
