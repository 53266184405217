import React from 'react';
import { Button, Tooltip, Zoom } from '@mui/material';

function PassedButton(props) {
    // console.log('props.disabled', props.disabled);
    return (
        <Tooltip
            TransitionComponent={Zoom}
            TransitionProps={{ timeout: 600 }}
            title={
                props.disabled
                    ? `You have reached the maximum number of users for your subscription. If you'd like to add more, please delete another user or contact us to update your subscription`
                    : ''
            }
        >
            <span>
                <Button
                    style={{
                        backgroundColor: `${props.disabled ? '#bababa' : '#FCBA04'}`,
                        color: 'black',
                        margin: '0.5rem',
                        padding: '1rem',
                        borderRadius: '10px',
                        lineHeight: '1.25rem',
                    }}
                    variant='contained'
                    onClick={props.onClick}
                    disabled={props.disabled}
                >
                    {props.icon} &nbsp;
                    {props.text}
                </Button>
            </span>
        </Tooltip>
    );
}

export default PassedButton;
